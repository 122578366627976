import React from 'react'
import Mocker from '../reusable/mocker'
import Neuron from './../../images/neuron.png'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 14 px;
  }
`

function Platform() {
  return (
    <Mocker
      title='Prix Saúde: Plataforma focada em eficiência, integralização e transparência na gestão da saúde pública'
      buttonText='Acesse a Plataforma'
      linkTitle='Acesse a Plataforma'
      largeOrangeButton
      image={Neuron}
      imageAlt='Mockup de um smartphone contendo algumas telas da solução Prix.Neuron'
      link={'https://prixneuron.com/'}
      target={'_blank'}
      imageOnTopMobile
      children={
        <>
          <Text>
            O Prix Saúde gera transparência na saúde pública por meio gestão e inteligência de
            dados. A plataforma é uma healthtech focada na gestão de dados da saúde a nível de
            atenção básica.
          </Text>
          <Text>
            Trabalha diretamente com os cidadãos e também reúne grupos de risco e imunização,
            utilizando inteligência artificial e big data para prever e melhorar a qualidade de vida
            das pessoas, bem como entregar informações estratégicas para a melhor abordagem e
            atendimento aos cidadãos.
          </Text>
        </>
      }
    />
  )
}

export default Platform
