import React, { Suspense } from 'react'
import './css/reset.css'
import './css/index.css'
import HomeScreen from './components/home/homeScreen'
import { Route, Routes } from 'react-router'
import HomeAbout from './components/about/homeAbout'
import HomePrizes from './components/prizes/homePrizes'
import HomeArtificialIntelligence from './components/artificialIntelligence/homeArtificialIntelligence'
import HomeNeuron from './components/neuron/homeNeuron'
import HomeCareers from './components/careers/homeCareers'
import Loader from './components/loader/loader'
import NotFound from './components/reusable/notFound'

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='about' element={<HomeAbout />} />
        <Route path='about/:sectionId' element={<HomeAbout />} />
        <Route path='prizes' element={<HomePrizes />} />
        <Route path='prizes/:sectionId' element={<HomePrizes />} />
        <Route path='artificialIntelligence' element={<HomeArtificialIntelligence />} />
        <Route path='artificialIntelligence/:sectionId' element={<HomeArtificialIntelligence />} />
        <Route path='neuron' element={<HomeNeuron />} />
        <Route path='neuron/:sectionId' element={<HomeNeuron />} />
        <Route path='careers' element={<HomeCareers />} />
        <Route path='careers/:sectionId' element={<HomeCareers />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default App
