import React, { useState } from 'react'
import Tania from './../../../images/bosses/tania.jpg'
import Petry from './../../../images/bosses/petry.jpg'
import Calza from './../../../images/bosses/calza.jpg'
import styled from 'styled-components'
import { primaryColors, selectedColors } from 'src/design/design'

const CarouselHolder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const BossContainer = styled.div`
  position: relative;
  display: inline-block;
`

const Buttons = styled.div`
  .button1 {
    position: absolute;
    top: 50%;
    left: 0;
  }

  .button2 {
    position: absolute;
    top: 50%;
    right: 0;
  }
`

const Button = styled.button`
  background-color: ${selectedColors.gray};
  opacity: 0.8;
  padding: 10px;
  margin: 5px;
  border: none;
`

const prixBosses = [
  {
    name: 'Tânia Patzlaff',
    image: Tania,
    title: 'CMO',
    alternativeText: 'Foto da CMO da Prix.Tech Tânia Patzlaff',
  },
  {
    name: 'Mateus Calza',
    image: Calza,
    title: 'CTO',
    alternativeText: 'Foto do CTO da Prix.Tech Mateus Calza',
  },
  {
    name: 'Felippe Petry',
    image: Petry,
    title: 'CEO',
    alternativeText: 'Foto do CEO da Prix.Tech Felippe Petry',
  },
]

const Boss = styled.img`
  width: 320px;
  height: 480px;
`

const NamingHolder = styled.div`
  padding-top: 20px;
`

const Name = styled.h1`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${primaryColors.blue};
  text-align: center;
`

const Title = styled.h1`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: ${primaryColors.blue};
  text-align: center;
`

function Carousel() {
  const [bossCount, setBossCount] = useState<number>(0)

  const HandleNext = () => {
    if (bossCount >= 2) {
      setBossCount(0)
    } else {
      setBossCount(bossCount + 1)
    }
  }

  const HandlePrevious = () => {
    if (bossCount <= 0) {
      setBossCount(2)
    } else {
      setBossCount(bossCount - 1)
    }
  }

  return (
    <CarouselHolder>
      <BossContainer>
        <Boss src={prixBosses[bossCount].image} alt={prixBosses[bossCount].alternativeText} />
        <Buttons>
          <Button className='button1' onClick={() => HandlePrevious()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={20}
              height={20}
              viewBox='0 0 24 24'
              fill='none'
              stroke={'#000'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path d='M19 12H6M12 5l-7 7 7 7' />
            </svg>
          </Button>
          <Button className='button2' onClick={() => HandleNext()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={20}
              height={20}
              viewBox='0 0 24 24'
              fill='none'
              stroke={'#000'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path d='M5 12h13M12 5l7 7-7 7' />
            </svg>
          </Button>
        </Buttons>
      </BossContainer>
      <NamingHolder>
        <Name>{prixBosses[bossCount].name}</Name>
        <Title>{prixBosses[bossCount].title}</Title>
      </NamingHolder>
    </CarouselHolder>
  )
}

export default Carousel
