import React, { useEffect, useRef, useState } from 'react'
import { complementaryColors } from 'src/design/design'
import styled from 'styled-components'
import globeVideo from '../../videos/globe.mp4'
import globe768Video from '../../videos/globe-768.mp4'
import Specialist from './specialist'
import { useMedia, useNetworkState } from 'react-use'

const Section = styled.section`
  display: flex;
  height: 900px;
  height: 100vh;
  position: relative;
  background: black;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition: filter 1s linear;
    will-change: filter;
    filter: blur(5px);
  }

  main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-size 1.8s linear;
    will-change: background-size;
    background-image: radial-gradient(
      circle at right,
      transparent 15%,
      rgba(2, 2, 2, 0.97),
      ${complementaryColors.crowBlackBlue}
    );
    background-repeat: no-repeat;
    background-size: 90%;
  }

  &.running video,
  &.middle video,
  &.stop video {
    filter: blur(0);
  }

  &.middle main,
  &.stop main {
    background-size: 110%;
  }

  @media (max-width: 768px) {
    &.middle main,
    &.stop main,
    main {
      background: linear-gradient(
        to bottom,
        rgba(2, 2, 2, 0.4),
        ${complementaryColors.armorWash},
        ${complementaryColors.crowBlackBlue}
      );
    }
  }
`

function StartScreen() {
  const isMobile = useMedia('(max-width: 768px)')
  const network = useNetworkState()

  const video = isMobile || network.saveData ? globe768Video : globeVideo

  const [videoPart, setVideoPart] = useState<'waiting' | 'running' | 'middle' | 'stop'>('waiting')
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const currentVideo = videoRef.current
    if (!currentVideo) {
      return
    }

    setVideoPart('running')
    currentVideo.currentTime = 0

    const handleTimeUpdate = () => {
      if (currentVideo.currentTime >= 10) {
        setVideoPart('stop')
      } else if (currentVideo.currentTime > 7.5) {
        setVideoPart('middle')
      }
    }
    currentVideo.addEventListener('timeupdate', handleTimeUpdate)
    currentVideo.play()

    return () => {
      currentVideo.removeEventListener('timeupdate', handleTimeUpdate)
    }
    // eslint-disable-next-line
  }, [videoRef.current, video])

  return (
    <Section className={videoPart}>
      <video playsInline muted src={video} ref={videoRef} />
      <main>
        <Specialist />
      </main>
    </Section>
  )
}

export default StartScreen
