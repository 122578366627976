import React, { useEffect } from 'react'
import NavBar from '../reusable/navbar'
import Footer from '../reusable/footer'
import Cover from '../reusable/cover'
import Platform from './platform'
import Advantage from './advantage'
import PublicHealth from './publicHealth'
import Agility from './agility'
import ScrollToTop from '../reusable/scrollToTop'
import { useParams } from 'react-router'
import { scroller } from 'react-scroll'

function HomeNeuron() {
  const { sectionId } = useParams()

  useEffect(() => {
    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: true,
      })
    }
  }, [sectionId])

  return (
    <>
      <ScrollToTop />
      <Cover title={'Solução em saúde'} />
      <Platform />
      <PublicHealth />
      <Agility />
      <Advantage />
      <Footer babyBlue />
      <NavBar changeBackground />
    </>
  )
}

export default HomeNeuron
