import React, { useState } from 'react'
import SmallModal from 'src/components/reusable/smallModal'
import { complementaryColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import { ReactComponent as StrategicManagement } from './../../../images/whiteLabelIcons/white/strategic-management.svg'
import { ReactComponent as Georeferencing } from './../../../images/whiteLabelIcons/white/georeferencing.svg'
import { ReactComponent as DisruptiveSolutions } from './../../../images/whiteLabelIcons/white/disruptive-solutions.svg'
import { ReactComponent as ArtificialIntelligence } from './../../../images/whiteLabelIcons/white/artificial-intelligence.svg'
import { ReactComponent as TemporalAnalysis } from './../../../images/whiteLabelIcons/white/temporal-analysis.svg'
import {
  artificialIntelligence,
  citizenApp,
  georeferencing,
  healthConsultancy,
  publicManagement,
} from 'src/components/reusable/publicHealthText'

const Elements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;

  @media (max-width: 960px) {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    margin: 24px;
    gap: 13px 16px;
  }
`

const Element = styled.div`
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${complementaryColors.rajahOrange};
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`

const ElementText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${primaryColors.blue};

  @media (max-width: 768px) {
    color: #fff;
  }
`

function MobileElements() {
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()
  const [solutionType, setSolutionType] = useState<string>()
  const [backgroundColor, setBackgroundColor] = useState<string>()

  const handleModal = (typeOfSolution: string) => {
    if (modal === true) {
      setModal(false)
      setTitle('')
      setText('')
      setSolutionType('')
      setBackgroundColor('')
    }

    if (modal === false || solutionType !== typeOfSolution) {
      setModal(true)
      setSolutionType(typeOfSolution)
      switch (typeOfSolution) {
        case 'citizenApp':
          setTitle('App para o Cidadão')
          setText(citizenApp)
          setSolutionType('citizenApp')
          setBackgroundColor('citizenApp')
          break
        case 'georeferencing':
          setTitle('Georreferenciamento')
          setText(georeferencing)
          setSolutionType('georeferencing')
          setBackgroundColor('georeferencing')
          break
        case 'healthConsultancy':
          setTitle('Consultoria em Saúde e E-SUS')
          setText(healthConsultancy)
          setSolutionType('healthConsultancy')
          setBackgroundColor('healthConsultancy')
          break
        case 'artificialIntelligence':
          setTitle('Inteligência Artificial')
          setText(artificialIntelligence)
          setSolutionType('artificialIntelligence')
          setBackgroundColor('artificialIntelligence')
          break
        case 'publicManagement':
          setTitle('Gestão Pública')
          setText(publicManagement)
          setSolutionType('publicManagement')
          setBackgroundColor('publicManagement')
          break
      }
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setTitle('')
    setText('')
    setBackgroundColor('')
  }

  return (
    <>
      <Elements>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'georeferencing'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('georeferencing')}
        >
          <Georeferencing width={64} height={64} />
          <ElementText>Georreferenciamento</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'citizenApp'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('citizenApp')}
        >
          <DisruptiveSolutions width={64} height={64} />
          <ElementText>App para o Cidadão</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'georeferencing') || solutionType === 'citizenApp' ? (
        <SmallModal text={text} title={title} handleClose={handleCloseModal} />
      ) : null}
      <Elements>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'artificialIntelligence'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('artificialIntelligence')}
        >
          <ArtificialIntelligence width={64} height={64} />
          <ElementText>Inteligência Artificial</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'healthConsultancy'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('healthConsultancy')}
        >
          <TemporalAnalysis width={64} height={64} />
          <ElementText>Consultoria em Saúde e E-SUS</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'artificialIntelligence') ||
      solutionType === 'healthConsultancy' ? (
        <SmallModal text={text} title={title} handleClose={handleCloseModal} />
      ) : null}
      <Elements>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'publicManagement'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('publicManagement')}
        >
          <StrategicManagement width={64} height={64} />
          <ElementText>Gestão Pública</ElementText>
        </Element>
      </Elements>
      {modal && solutionType === 'publicManagement' ? (
        <SmallModal text={text} title={title} handleClose={handleCloseModal} />
      ) : null}
    </>
  )
}

export default MobileElements
