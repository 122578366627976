import React from 'react'
import Mocker from '../reusable/mocker'
import Prixters from './../../images/colaboradores.png'
import styled from 'styled-components'

const Container = styled.div`
  @media (max-width: 768px) {
    margin-top: 130px;
  }
`

function WorkWithUs() {
  return (
    <Container>
      <Mocker
        title='Seja um Prixter'
        buttonText='Conheça nosso Plano de Carreiras'
        image={Prixters}
        imageAlt={'Imagem com os avatares dos colaboradores da Prix.Tech'}
        link={'/careers'}
        linkTitle={'Conheça nossos Planos de Carreiras'}
        text='Nosso time multidisciplinar está focado no atendimento ao cliente de forma a construir soluções disruptivas para diferentes segmentos de mercado.  Acreditamos que produzir com excelência, paixão e integridade é a única forma de deixar um legado e melhorar a sociedade onde vivemos. Esse é o nosso propósito. É isso que nos move!'
        hideImageOnMobile={false}
        imageOnTopMobile
        titleColor='#000'
      />
    </Container>
  )
}

export default WorkWithUs
