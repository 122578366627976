import React from 'react'
import { lightVariationColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import Office from './../../images/office.png'
import { ReactComponent as Square } from './../../images/square.svg'

const Section = styled.section`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 485px;
  background-image: url(${Office});
  background-size: cover;
  background-color: ${lightVariationColors.blue};

  @media (max-width: 768px) {
    height: 225px;
  }
`

const Box = styled.div`
  margin-left: 130px;

  @media (max-width: 1100px) {
    margin-left: 40px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 420px;
  height: 81px;
  background-color: ${primaryColors.blue};
  gap: 22px;
  padding-left: 70px;

  @media (max-width: 1100px) {
    width: 310px;
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    height: 70px;
    width: 254px;
    gap: 13px;
  }
`

const Text = styled.h1`
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 700;
  }
`

interface CoverProps {
  title: string
}

function Cover({ title }: CoverProps) {
  return (
    <Section>
      <Box>
        <TitleBox>
          <Square width={8} height={8} />
          <Text>{title}</Text>
        </TitleBox>
      </Box>
    </Section>
  )
}

export default Cover
