import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import One from './../../images/onu/one.png'
import Two from './../../images/onu/two.png'
import Three from './../../images/onu/three.png'
import Four from './../../images/onu/four.png'
import Five from './../../images/onu/five.png'
import Six from './../../images/onu/six.png'
import Seven from './../../images/onu/seven.png'
import Eight from './../../images/onu/eight.png'
import Nine from './../../images/onu/nine.png'
import Ten from './../../images/onu/ten.png'
import Eleven from './../../images/onu/eleven.png'
import Twelve from './../../images/onu/twelve.png'
import Thirteen from './../../images/onu/thirteen.png'
import Fourteen from './../../images/onu/fourteen.png'
import Fifteen from './../../images/onu/fifteen.png'
import Sixteen from './../../images/onu/sixteen.png'
import Seventeen from './../../images/onu/seventeen.png'
import BigOnuCube from './../../images/cubesAndMarkers/big_onu_cube.png'
import { useMedia } from 'react-use'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  background-color: #fff;
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: #000;
  line-height: 44px;
  text-align: center;
  margin-bottom: 90px;

  @media (max-width: 768px) {
    margin-top: 120px;
    margin-bottom: 60px;
    font-size: 35px;
    line-height: 34px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 50px;
  gap: 38px;
  margin-left: 440px;
  margin-right: 440px;

  @media (max-width: 1450px) {
    margin-left: 350px;
    margin-right: 350px;
  }

  @media (max-width: 1280px) {
    margin-left: 250px;
    margin-right: 250px;
  }

  @media (max-width: 1150px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
    margin-left: 50px;
    margin-right: 50px;
  }
`

const Image = styled.img``

const MobileGrid = styled.div`
  @media (max-width: 985px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

const DesktopGrid = styled.div`
  display: flex;
  flex-direction: row;
  margin: -1px;
`

const ButtonHolder = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    align-self: center;
    margin-bottom: 153px;
  }
`

const OutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 285px;
    height: 45px;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

const BigOnuCubeImage = styled.img`
  display: flex;
  align-self: flex-start;
  position: relative;
  top: -200px;

  @media (max-width: 768px) {
    display: none;
  }
`

function OnuScreen() {
  const isMobile = useMedia('(max-width: 985px)')

  return (
    <Section id={'onu'}>
      <Title>Alinhados com os objetivos sustentáveis da ONU</Title>
      {isMobile ? (
        <MobileGrid>
          <Image src={One} alt='Erradicação da pobreza' />
          <Image src={Two} alt='Fome zero e agricultura sustentável' />
          <Image src={Three} alt='Saúde e bem-estar' />
          <Image src={Four} alt='Educação de qualidade' />
          <Image src={Five} alt='Igualdade de Gênero' />
          <Image src={Six} alt='Água potável e saneamento' />
          <Image src={Seven} alt='Energia limpa e acessível' />
          <Image src={Eight} alt='Trabalho decente e crescimento econômico' />
          <Image src={Nine} alt='Indústria, inovação e infraestrutura' />
          <Image src={Ten} alt='Redução das desigualdades' />
          <Image src={Eleven} alt='Cidades e comunidades sustentáveis' />
          <Image src={Twelve} alt='Consumo e produção responsáveis' />
          <Image src={Thirteen} alt='Ação contra a mudança global' />
          <Image src={Fourteen} alt='Vida na água' />
          <Image src={Fifteen} alt='Vida terrestre' />
          <Image src={Sixteen} alt='Paz, justiça e instituições eficazes' />
          <Image src={Seventeen} alt='Parcerias e meios de implementação' />
        </MobileGrid>
      ) : (
        <>
          <DesktopGrid>
            <Image src={One} alt='Erradicação da pobreza' />
            <Image src={Two} alt='Fome zero e agricultura sustentável' />
            <Image src={Three} alt='Saúde e bem-estar' />
            <Image src={Four} alt='Educação de qualidade' />
            <Image src={Five} alt='Igualdade de Gênero' />
            <Image src={Six} alt='Água potável e saneamento' />
            <Image src={Seven} alt='Energia limpa e acessível' />
            <Image src={Eight} alt='Trabalho decente e crescimento econômico' />
            <Image src={Nine} alt='Indústria, inovação e infraestrutura' />
          </DesktopGrid>
          <DesktopGrid>
            <Image src={Ten} alt='Redução das desigualdades' />
            <Image src={Eleven} alt='Cidades e comunidades sustentáveis' />
            <Image src={Twelve} alt='Consumo e produção responsáveis' />
            <Image src={Thirteen} alt='Ação contra a mudança global' />
            <Image src={Fourteen} alt='Vida na água' />
            <Image src={Fifteen} alt='Vida terrestre' />
            <Image src={Sixteen} alt='Paz, justiça e instituições eficazes' />
            <Image src={Seventeen} alt='Parcerias e meios de implementação' />
          </DesktopGrid>
        </>
      )}
      <TextHolder>
        <Text>
          Nossa equipe trabalha para ser disruptiva e construir um legado que transforma o país e
          nesse sentido trabalhamos alinhados com os objetivos e metas principais da Agenda 2030 da
          ONU.
        </Text>
        <Text>
          Nossos projetos se destacam em Saúde e bem-estar; Educação de qualidade; Trabalho decente
          e crescimento econômico; Indústria, inovação e infraestrutura e Cidades e comunidades
          sustentáveis.
        </Text>
        <Text>
          Acreditamos que ao construir um legado que transforma o país estamos transformando o mundo
          em um lugar melhor.
        </Text>
      </TextHolder>
      <ButtonHolder>
        <LinkAct
          title='Saiba mais sobre a Agenda 2030 (Link externo)'
          href='https://brasil.un.org/pt-br/sdgs'
        >
          <OutlinedButton>Saiba mais sobre a Agenda 2030</OutlinedButton>
        </LinkAct>
      </ButtonHolder>
      <BigOnuCubeImage
        src={BigOnuCube}
        alt='Cubo laranja ao lado dos objetivos sustentáveis da ONU'
      />
    </Section>
  )
}

export default OnuScreen
