import { css } from 'styled-components'

export const complementaryColors = {
  crowBlackBlue: '#0b213c',
  armorWash: '#020202',
  graySwan: '#B4B4B4',
  rajahOrange: '#FFA561',
}

export const primaryColors = {
  blue: '#0C2340',
  orange: '#FF6900',
  gray: '#595959',
  green: '#26D07C',
}

export const lightVariationColors = {
  blue: '#1B4D80',
  orange: '#FF8731',
  gray: '#8A8A8A',
  green: '#4FE097',
}

export const darkVariationColors = {
  blue: '#001021',
  orange: '#A54300',
  gray: '#1E1E1E',
  green: '#004B24',
}

export const hoverColors = {
  blue: '#2A77BF',
  orange: '#FFA561',
  gray: '#BCBCBC',
  green: '#78EFB3',
}

export const selectedColors = {
  blue: '#9DD1FF',
  orange: '#FFE6D2',
  gray: '#EDEDED',
  green: '#BDFFDD',
}

export const containerCSS = css`
  display: flex;
  flex: 0 1 1400px;
  gap: 30px;
  flex-direction: row;
`

export const BallCSS = css`
  position: relative;
  background-color: ${primaryColors.orange};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0 0 0 0 rgba(255, 105, 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  &:hover {
    cursor: pointer;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 105, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`
