export const jobs = []

export const oldJobs = [
  {
    title: 'Desenvolvedor back-end - NodeJS',
    workMode: 'Home Office',
    contract: 'CLT',
    requirements: [
      { requirementDescription: 'Conhecimento de NodeJS' },
      { requirementDescription: 'Experiência com SQL' },
    ],
    differentials: [
      { differentialDescription: 'Experiência com PostgreSQL' },
      { differentialDescription: 'Experiência com TypeScript' },
      { differentialDescription: 'Experiência com análise de dados' },
      { differentialDescription: 'Experiência com cloud e cultura DevOps' },
      { differentialDescription: 'Conhecimento em segurança da informação' },
    ],
    responsibilities: [
      { responsibilityDescription: 'Desenvolver APIs' },
      { responsibilityDescription: 'Desenvolver ferramentas de integração' },
      { responsibilityDescription: 'Desenvolver projetos de dados e queries SQL' },
    ],
    remuneration: '6.000,00',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLScZOFkVEF3_WT2SErbtv8_bCg1HS_uH1J2ZqYql5H5gwZnp3g/viewform',
  },
  {
    title: 'Desenvolvedor back-end',
    workMode: 'Home Office',
    contract: 'CLT',
    requirements: [
      { requirementDescription: 'Experiência com NodeJS' },
      { requirementDescription: 'Experiência com SQL' },
      {
        requirementDescription:
          'Capacidade de desenvolver e otimizar queries SQL complexas e trabalhar com grandes volumes de dados',
      },
    ],
    differentials: [
      { differentialDescription: 'Experiência com PostgreSQL' },
      { differentialDescription: 'Experiência com TypeScript' },
      { differentialDescription: 'Experiência com Python' },
      { differentialDescription: 'Experiência com análise de dados' },
      { differentialDescription: 'Experiência com cloud e cultura DevOps' },
      { differentialDescription: 'Conhecimento em segurança da informação' },
      {
        differentialDescription:
          'Familiaridade com conceitos de processamento de dados e backpressure em NodeJS',
      },
    ],
    responsibilities: [
      { responsibilityDescription: 'Desenvolver APIs' },
      { responsibilityDescription: 'Desenvolver ferramentas de integração' },
      { responsibilityDescription: 'Desenvolver projetos de dados e queries SQL' },
      { responsibilityDescription: 'Realizar análises de dados e integrá-las ao back-end' },
      {
        responsibilityDescription:
          'Colaborar com equipes para criar soluções otimizadas e eficientes',
      },
      {
        responsibilityDescription:
          'Garantir a qualidade e a segurança das aplicações através de testes e boas práticas de desenvolvimento',
      },
    ],
    remuneration: '6.000,00',
    link: 'https://forms.gle/s5ecn5obZD2N1cHG6',
  },
  {
    title: 'Desenvolvedor front-end',
    workMode: 'Home Office',
    contract: 'CLT',
    requirements: [
      { requirementDescription: 'Experiência com JavaScript' },
      { requirementDescription: 'Experiência com CSS' },
      {
        requirementDescription: 'Habilidade em construir interfaces responsivas e adaptáveis',
      },
    ],
    differentials: [
      { differentialDescription: 'Experiência com React' },
      { differentialDescription: 'Experiência com TypeScript' },
      {
        differentialDescription:
          'Experiência com análise de dados e uso de bibliotecas para gráficos',
      },
    ],
    responsibilities: [
      { responsibilityDescription: 'Desenvolvimento e manutenção de interfaces web responsivas' },
      {
        responsibilityDescription:
          'Implementação de designs complexos utilizando React e bibliotecas relacionadas',
      },
      { responsibilityDescription: 'Integração de interfaces com back-ends em Python e NodeJS' },
      {
        responsibilityDescription:
          'Colaborar com equipes para criar soluções otimizadas e eficientes',
      },
      {
        responsibilityDescription:
          'Garantir a qualidade e a segurança das aplicações através de testes e boas práticas de desenvolvimento',
      },
    ],
    remuneration: '6.000,00',
    link: 'https://forms.gle/vfAE71RUKxnY7zF78',
  },
  {
    title: 'Estágio para análise de dados',
    workMode: 'Home Office',
    contract: 'Estágio',
    internship: true,
    requirements: [
      {
        requirementDescription:
          'Estar cursando graduação ou técnico nas áreas de Tecnologia da Informação, Estatística, Engenharia, Biologia, Epidemiologia ou áreas afins',
      },
      { requirementDescription: 'Conhecimento básico de programação' },
    ],
    differentials: [
      { differentialDescription: 'Conhecimento de Python' },
      { differentialDescription: 'Conhecimento de SQL' },
      { differentialDescription: 'Conhecimento de GIT' },
      { differentialDescription: 'Conhecimento na área da saúde' },
    ],
    responsibilities: [
      {
        responsibilityDescription:
          'Participar de projetos de pesquisa e desenvolvimento na área de análise de dados',
      },
      {
        responsibilityDescription:
          'Contribuir com a equipe na construção de relatórios e visualizações de dados',
      },
      {
        responsibilityDescription:
          'Auxiliar na coleta, processamento e análise de grandes conjuntos de dados',
      },
    ],
    remuneration: '1.000,00',
    link: 'https://forms.gle/tZ1UtUuwZUPN3J5o8',
  },
]
