import React, { useEffect } from 'react'
import NavBar from '../reusable/navbar'
import Footer from '../reusable/footer'
import Cover from '../reusable/cover'
import CompanyAbout from './companyAbout'
import WorkWithUs from './workWithUs'
import QuoteScreen from './quoteScreen'
import OnuScreen from './onuScreen'
import ScrollToTop from '../reusable/scrollToTop'
import { useParams } from 'react-router'
import { scroller } from 'react-scroll'

function HomeAbout() {
  const { sectionId } = useParams()

  useEffect(() => {
    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: true,
      })
    }
  }, [sectionId])

  return (
    <>
      <ScrollToTop />
      <Cover title={'Quem somos'} />
      <CompanyAbout />
      <QuoteScreen />
      <WorkWithUs />
      <OnuScreen />
      <Footer />
      <NavBar changeBackground />
    </>
  )
}

export default HomeAbout
