import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import DesktopElementCompanyAbout from './companyAboutComponents/desktopElements'
import MobileElementCompanyAbout from './companyAboutComponents/mobileElements'

const Section = styled.section`
  flex: 1;
  display: flex;
  height: auto;
  background-color: #ffffff;
`

function CompanyAbout() {
  const isMobile = useMedia('(max-width: 974px)')

  return (
    <Section>{isMobile ? <MobileElementCompanyAbout /> : <DesktopElementCompanyAbout />}</Section>
  )
}

export default CompanyAbout
