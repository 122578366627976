import React, { useState } from 'react'
import { primaryColors, selectedColors } from 'src/design/design'
import styled from 'styled-components'
import { jobs } from 'src/components/reusable/openPositionsText'
import { ReactComponent as JobArrowLeft } from './../../../images/job-arrow-left.svg'
import { ReactComponent as JobArrowDown } from './../../../images/job-arrow-down.svg'
import OldJobs from './oldJobs'

interface jobsInterface {
  title: string
  workMode: string
  contract: string
  internship: boolean
  requirements: {
    requirementDescription: string
  }[]
  differentials: {
    differentialDescription: string
  }[]
  responsibilities: {
    responsibilityDescription: string
  }[]
  remuneration: string
  link: string
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const TopDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10%;
`

const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10%;
  margin-top: 30px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const Title = styled.h2`
  color: #000;
  font-size: 2.75em;
  font-weight: 700;
`

const SecondTitle = styled.h6`
  color: #000;
  font-size: 0.75em;
  font-weight: 700;
`

const ButtonHolder = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 768px) {
    align-self: center;
  }
`

export const OutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 285px;
    height: 45px;
  }
`

export const OrangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 35px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 10px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 255px;
    height: 35px;
    font-size: 15px;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

const NoJobs = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${primaryColors.gray};
  padding-left: 20px;
`

const Jobs = styled.div``

const JobHeader = styled.div`
  margin: 21px 30px;
`

const JobTitleHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

const JobTitle = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${primaryColors.blue};
  padding-left: 20px;
`

const JobSubtitle = styled.h1`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${primaryColors.blue};
  padding-left: 32px;
`

// const SecondaryJobSubtitle = styled.h1`
//   font-weight: 200;
//   font-size: 13px;
//   line-height: 21px;
//   color: ${primaryColors.blue};
//   padding-left: 32px;
// `

const JobMessageBody = styled.div`
  margin: 31px 50px;
`

const JobText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000;
`

const JobFiller = styled.div`
  margin: 21px 50px;
`

const JobFillerTitle = styled.h1`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000;
`

const JobFillerText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  padding-left: 15px;
`

const WageDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10%;
  margin-top: 80px;
  background-color: ${primaryColors.blue};

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const WageTitle = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
`

const WageText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
`

const Salary = styled.strong`
  font-weight: 700;
`

function VacantJob() {
  const [openPositions] = useState<any>(jobs)
  const [modal, setModal] = useState<string>()

  function HandleModal(jobTitle: string) {
    setModal(jobTitle)

    if (modal === jobTitle) {
      setModal(undefined)
    }
  }

  return (
    <Container>
      <DescriptionContainer>
        <TopDescription>
          <Title>Algumas de nossas vagas </Title>
          {/* <SecondTitle>Inscrições para as vagas encerram na terça-feira, dia 20/02. </SecondTitle> */}
        </TopDescription>
        <BottomDescription>
          {!openPositions.length ? (
            <Jobs>
              <NoJobs>Não há vagas no momento.</NoJobs>
            </Jobs>
          ) : (
            openPositions.map((job: jobsInterface) => (
              <Jobs
                style={{ backgroundColor: modal === job.title ? selectedColors.gray : '' }}
                onClick={() => HandleModal(job.title)}
              >
                <JobHeader>
                  <JobTitleHolder>
                    {modal === job.title ? (
                      <>
                        <JobArrowDown width={11} height={12} />
                        <JobTitle>{job.title}</JobTitle>
                      </>
                    ) : (
                      <>
                        <JobArrowLeft width={11} height={12} />
                        <JobTitle>{job.title}</JobTitle>
                      </>
                    )}
                  </JobTitleHolder>
                  <JobSubtitle>{job.workMode + ' - ' + job.contract}</JobSubtitle>
                  {/* <SecondaryJobSubtitle>
                    Inscrições encerram na terça-feira, dia 20/02.
                  </SecondaryJobSubtitle> */}
                </JobHeader>
                {modal === job.title ? (
                  <>
                    <JobMessageBody>
                      <JobText>
                        Quer trabalhar com uma empresa que recebeu o maior prêmio de Inovação de
                        Santa Catarina? O Prêmio Stemmer? A Prix Tech está com vaga aberta para{' '}
                        {job.title}.
                      </JobText>
                    </JobMessageBody>
                    <JobFiller>
                      <JobFillerTitle>Requisitos:</JobFillerTitle>
                      {job.requirements.map((requirement: any) => (
                        <JobFillerText>• {requirement.requirementDescription}</JobFillerText>
                      ))}
                    </JobFiller>
                    <JobFiller>
                      <JobFillerTitle>Diferenciais:</JobFillerTitle>
                      {job.differentials.map((differential: any) => (
                        <JobFillerText>• {differential.differentialDescription}</JobFillerText>
                      ))}
                    </JobFiller>
                    <JobFiller>
                      <JobFillerTitle>Responsabilidades:</JobFillerTitle>
                      {job.responsibilities.map((responsibility: any) => (
                        <JobFillerText>• {responsibility.responsibilityDescription}</JobFillerText>
                      ))}
                    </JobFiller>
                    {!job.internship ? (
                      <JobFiller>
                        <JobFillerTitle>Benefícios:</JobFillerTitle>
                        <JobFillerText>• Vale alimentação</JobFillerText>
                        <JobFillerText>• Kit de onboarding</JobFillerText>
                        <JobFillerText>• Bônus de indicação</JobFillerText>
                        <JobFillerText>• Bônus por tempo de serviço</JobFillerText>
                        <JobFillerText>• Licença-maternidade e licença-paternidade</JobFillerText>
                        <JobFillerText>• Plano de saúde UNIMED</JobFillerText>
                        <JobFillerText>• Seguro de vida</JobFillerText>
                      </JobFiller>
                    ) : (
                      <JobFiller>
                        <JobFillerTitle>Benefícios:</JobFillerTitle>
                        <JobFillerText>• Kit de onboarding</JobFillerText>
                        <JobFillerText>• Bônus de indicação</JobFillerText>
                        <JobFillerText>• Bônus por tempo de serviço</JobFillerText>
                        <JobFillerText>• Seguro de vida</JobFillerText>
                      </JobFiller>
                    )}
                    <WageDescription>
                      <WageTitle>Remuneração:</WageTitle>
                      <WageText>
                        <Salary>R${job.remuneration}</Salary> -{' '}
                        {job.internship ? '30h/semanais' : '44h/semanais'}
                      </WageText>
                      {!job.internship ? (
                        <WageText>+ Benefícios</WageText>
                      ) : (
                        <WageText>+ Seguro de vida</WageText>
                      )}
                      <ButtonHolder>
                        <LinkAct
                          title='Candidatar a vaga (Link externo)'
                          target='_blank'
                          href={job.link}
                          rel='noopener noreferrer'
                        >
                          <OrangeButton>Candidatar a vaga</OrangeButton>
                        </LinkAct>
                      </ButtonHolder>
                      <WageText>
                        <p>Inscrições encerram na terça-feira, dia 20/02.</p>
                      </WageText>
                    </WageDescription>
                  </>
                ) : null}
              </Jobs>
            ))
          )}
        </BottomDescription>
      </DescriptionContainer>
      <OldJobs />
    </Container>
  )
}

export default VacantJob
