import React, { useState } from 'react'
import {
  artificialIntelligence,
  dataAnalysis,
  disruptiveSolutions,
  georeferencing,
  strategicManagement,
  temporalAnalysis,
} from 'src/components/reusable/ourSolutionsText'
import SmallModal from 'src/components/reusable/smallModal'
import { complementaryColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import { ReactComponent as DataAnalysis } from './../../../images/whiteLabelIcons/white/data-analysis.svg'
import { ReactComponent as StrategicManagement } from './../../../images/whiteLabelIcons/white/strategic-management.svg'
import { ReactComponent as Georeferencing } from './../../../images/whiteLabelIcons/white/georeferencing.svg'
import { ReactComponent as DisruptiveSolutions } from './../../../images/whiteLabelIcons/white/disruptive-solutions.svg'
import { ReactComponent as ArtificialIntelligence } from './../../../images/whiteLabelIcons/white/artificial-intelligence.svg'
import { ReactComponent as TemporalAnalysis } from './../../../images/whiteLabelIcons/white/temporal-analysis.svg'

const Elements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;

  @media (max-width: 960px) {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    margin: 6px 24px;
    gap: 13px 16px;
  }
`

const Element = styled.div`
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${complementaryColors.rajahOrange};
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`

const ElementText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${primaryColors.blue};

  @media (max-width: 768px) {
    color: #fff;
  }
`

function MobileElements() {
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()
  const [solutionType, setSolutionType] = useState<string>()
  const [backgroundColor, setBackgroundColor] = useState<string>()

  const handleModal = (typeOfSolution: string) => {
    if (modal === true) {
      setModal(false)
      setTitle('')
      setText('')
      setSolutionType('')
      setBackgroundColor('')
    }

    if (modal === false || solutionType !== typeOfSolution) {
      setModal(true)
      setSolutionType(typeOfSolution)
      switch (typeOfSolution) {
        case 'dataAnalysis':
          setTitle('Análise de Dados')
          setText(dataAnalysis)
          setSolutionType('dataAnalysis')
          setBackgroundColor('dataAnalysis')
          break
        case 'strategicManagement':
          setTitle('Gestão Estratégica')
          setText(strategicManagement)
          setSolutionType('strategicManagement')
          setBackgroundColor('strategicManagement')
          break
        case 'georeferencing':
          setTitle('Georreferenciamento')
          setText(georeferencing)
          setSolutionType('georeferencing')
          setBackgroundColor('georeferencing')
          break
        case 'disruptiveSolutions':
          setTitle('Soluções Disruptivas')
          setText(disruptiveSolutions)
          setSolutionType('disruptiveSolutions')
          setBackgroundColor('disruptiveSolutions')
          break
        case 'artificialIntelligence':
          setTitle('Inteligência Artificial')
          setText(artificialIntelligence)
          setSolutionType('artificialIntelligence')
          setBackgroundColor('artificialIntelligence')
          break
        case 'temporalAnalysis':
          setTitle('Análise Temporal')
          setText(temporalAnalysis)
          setSolutionType('temporalAnalysis')
          setBackgroundColor('temporalAnalysis')
          break
      }
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setTitle('')
    setText('')
    setBackgroundColor('')
  }

  return (
    <>
      <Elements>
        <Element
          title='Análise de Dados'
          style={{
            backgroundColor:
              backgroundColor === 'dataAnalysis'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('dataAnalysis')}
        >
          <DataAnalysis width={64} height={64} />
          <ElementText>Análise de Dados</ElementText>
        </Element>
        <Element
          title='Gestão Estratégica'
          style={{
            backgroundColor:
              backgroundColor === 'strategicManagement'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('strategicManagement')}
        >
          <StrategicManagement width={64} height={64} />
          <ElementText>Gestão Estratégica</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'dataAnalysis') || solutionType === 'strategicManagement' ? (
        <SmallModal text={text} title={title} handleClose={handleCloseModal} />
      ) : null}
      <Elements>
        <Element
          title='Georreferenciamento'
          style={{
            backgroundColor:
              backgroundColor === 'georeferencing'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('georeferencing')}
        >
          <Georeferencing width={64} height={64} />
          <ElementText>Georreferenciamento</ElementText>
        </Element>
        <Element
          title='Soluções Disruptivas'
          style={{
            backgroundColor:
              backgroundColor === 'disruptiveSolutions'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('disruptiveSolutions')}
        >
          <DisruptiveSolutions width={64} height={64} />
          <ElementText>Soluções Disruptivas</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'georeferencing') || solutionType === 'disruptiveSolutions' ? (
        <SmallModal text={text} title={title} handleClose={handleCloseModal} />
      ) : null}
      <Elements>
        <Element
          title='Inteligência Artificial'
          style={{
            backgroundColor:
              backgroundColor === 'artificialIntelligence'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('artificialIntelligence')}
        >
          <ArtificialIntelligence width={64} height={64} />
          <ElementText>Inteligência Artificial</ElementText>
        </Element>
        <Element
          title='Análise Temporal'
          style={{
            backgroundColor:
              backgroundColor === 'temporalAnalysis'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('temporalAnalysis')}
        >
          <TemporalAnalysis width={64} height={64} />
          <ElementText>Análise Temporal</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'artificialIntelligence') ||
      solutionType === 'temporalAnalysis' ? (
        <SmallModal text={text} title={title} handleClose={handleCloseModal} />
      ) : null}
    </>
  )
}

export default MobileElements
