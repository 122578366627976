import React from 'react'
import { primaryColors, selectedColors } from 'src/design/design'
import styled from 'styled-components'
import { ReactComponent as Close } from './../../images/close.svg'

const Cluster = styled.div`
  margin-left: 320px;
  margin-right: 320px;
  margin-top: 80px;

  @media (max-width: 1500px) {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0;
  }
`

const Container = styled.div`
  display: flex;
  background-color: ${selectedColors.gray};
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`

const Holder = styled.div`
  display: flex;
  gap: 130px;
  flex-direction: row;
  align-items: center;
  padding: 50px;

  @media (max-width: 960px) {
    gap: 80px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px;
  }
`

const Title = styled.h1`
  color: ${primaryColors.blue};
  font-size: 1.2em;
  font-weight: 700;

  @media (max-width: 960px) {
    font-size: 1em;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const Text = styled.span`
  max-width: 600px;
  font-size: 14px;
`

const CloseButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

interface ModalProps {
  title: string | undefined
  text: string | undefined
  topDistance?: string
  bottomDistance?: string
  handleClose: Function
}

function Modal({ title, text, handleClose, topDistance, bottomDistance }: ModalProps) {
  return (
    <Cluster style={{ marginTop: `${topDistance}px`, marginBottom: `${bottomDistance}px` }}>
      <Container>
        <Holder>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Holder>
        <CloseButton onClick={() => handleClose()}>
          <Close width={22} height={22} />
        </CloseButton>
      </Container>
    </Cluster>
  )
}

export default Modal
