import React from 'react'
import Mocker from '../reusable/mocker'
import ArtificialIntelligence from './../../images/artificial-intelligence.png'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 14 px;
  }
`

function ModuleArtificialIntelligence() {
  return (
    <Mocker
      title='Prix Empresas: Transforme dados em ações embasadas assertivas'
      buttonText='Converse com nosso consultor'
      largeOrangeButton
      image={ArtificialIntelligence}
      imageAlt={'Imagem de um mockup contendo um print de tela do Prix Neuron'}
      link={'#Contact'}
      linkTitle={'Converse com nosso consultor'}
      rowReverse
      imageOnTopMobile
      children={
        <>
          <Text>
            A plataforma é capaz de realizar análises de dados e apresentá-los de forma ampla e
            assertiva, para que assim, os gestores sejam capazes de ter uma visão ampla de negócio,
            bem como, possam visualizar todas as oportunidades existentes, sejam para melhorias ou
            para ampliações, para investimento ou economia em segmentos específicos.
          </Text>
          <Text>
            Ela pode ser aplicada a qualquer empresa com banco de dados que possua referenciação
            geográfica.
          </Text>
          <Text>
            O sistema conta com recursos capazes de analisar e apresentar informações, com base nas
            fontes de dados obtidas, realizando a análise entre elas e plotando-as em diferentes
            tipos de mapas.
          </Text>
        </>
      }
    />
  )
}

export default ModuleArtificialIntelligence
