import React, { useState } from 'react'
import { complementaryColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import SmallModal from 'src/components/reusable/smallModal'
import { ReactComponent as FoodStamps } from './../../../images/benefits/food-stamps.svg'
import { ReactComponent as HealthInsurance } from './../../../images/benefits/health-insurance.svg'
import { ReactComponent as LifeInsurance } from './../../../images/benefits/life-insurance.svg'
import { ReactComponent as MaternityPaternityLeave } from './../../../images/benefits/maternity-paternity-leave.svg'
import { ReactComponent as OnboardKit } from './../../../images/benefits/onboard-kit.svg'
import { ReactComponent as ReferralBonus } from './../../../images/benefits/referral-bonus.svg'
import { ReactComponent as ServiceTimeBonus } from './../../../images/benefits/service-time-bonus.svg'
import {
  foodStamps,
  healthInsurance,
  lifeInsurance,
  maternityPaternityLeave,
  onboardKit,
  referralBonus,
  serviceTimeBonus,
} from 'src/components/reusable/benefitsText'

const Elements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 36px;
  padding-bottom: 20px;

  @media (max-width: 960px) {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    /* margin: 24px; */
    gap: 13px 16px;
  }
`

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  width: 215px;
  height: 150px;
  background-color: ${complementaryColors.rajahOrange};
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }
`

const ElementText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
`

function DesktopElementsBenefits() {
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()
  const [solutionType, setSolutionType] = useState<string>()
  const [backgroundColor, setBackgroundColor] = useState<string>()

  const handleModal = (typeOfTheSolution: string) => {
    if (modal === true) {
      setModal(false)
      setTitle('')
      setText('')
      setSolutionType('')
      setBackgroundColor('')
    }

    if (modal === false || solutionType !== typeOfTheSolution) {
      setModal(true)
      setSolutionType(typeOfTheSolution)
      switch (typeOfTheSolution) {
        case 'FoodStamps':
          setText(foodStamps)
          setSolutionType('FoodStamps')
          setBackgroundColor('FoodStamps')
          break
        case 'OnboardKit':
          setText(onboardKit)
          setSolutionType('OnboardKit')
          setBackgroundColor('OnboardKit')
          break
        case 'ReferralBonus':
          setText(referralBonus)
          setSolutionType('ReferralBonus')
          setBackgroundColor('ReferralBonus')
          break
        case 'ServiceTimeBonus':
          setText(serviceTimeBonus)
          setSolutionType('ServiceTimeBonus')
          setBackgroundColor('ServiceTimeBonus')
          break
        case 'MaternityPaternityLeave':
          setText(maternityPaternityLeave)
          setSolutionType('MaternityPaternityLeave')
          setBackgroundColor('MaternityPaternityLeave')
          break
        case 'HealthInsurance':
          setText(healthInsurance)
          setSolutionType('HealthInsurance')
          setBackgroundColor('HealthInsurance')
          break
        case 'LifeInsurance':
          setText(lifeInsurance)
          setSolutionType('LifeInsurance')
          setBackgroundColor('LifeInsurance')
          break
      }
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setTitle('')
    setText('')
    setBackgroundColor('')
  }

  return (
    <>
      <Elements>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'FoodStamps'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('FoodStamps')}
        >
          <FoodStamps width={64} height={64} />
          <ElementText>Vale-alimentação</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'OnboardKit'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('OnboardKit')}
        >
          <OnboardKit width={64} height={64} />
          <ElementText>Kit de Onboard</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'ReferralBonus'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('ReferralBonus')}
        >
          <ReferralBonus width={64} height={64} />
          <ElementText>Bônus de Indicação</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'ServiceTimeBonus'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('ServiceTimeBonus')}
        >
          <ServiceTimeBonus width={64} height={64} />
          <ElementText>Bônus por tempo de serviço</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'FoodStamps') ||
      solutionType === 'OnboardKit' ||
      solutionType === 'ReferralBonus' ||
      solutionType === 'ServiceTimeBonus' ? (
        <SmallModal
          topDistance={'20'}
          bottomDistance={'20'}
          text={text}
          title={title}
          handleClose={handleCloseModal}
        />
      ) : null}

      <Elements>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'MaternityPaternityLeave'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('MaternityPaternityLeave')}
        >
          <MaternityPaternityLeave width={64} height={64} />
          <ElementText>Licença-maternidade e Licença-paternidade</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'HealthInsurance'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('HealthInsurance')}
        >
          <HealthInsurance width={64} height={64} />
          <ElementText>Plano de Saúde</ElementText>
        </Element>
        <Element
          style={{
            backgroundColor:
              backgroundColor === 'LifeInsurance'
                ? primaryColors.orange
                : complementaryColors.rajahOrange,
          }}
          onClick={() => handleModal('LifeInsurance')}
        >
          <LifeInsurance width={64} height={64} />
          <ElementText>Seguro de Vida</ElementText>
        </Element>
      </Elements>
      {(modal && solutionType === 'MaternityPaternityLeave') ||
      solutionType === 'HealthInsurance' ||
      solutionType === 'LifeInsurance' ? (
        <SmallModal
          topDistance={'20'}
          bottomDistance={'20'}
          text={text}
          title={title}
          handleClose={handleCloseModal}
        />
      ) : null}
    </>
  )
}

export default DesktopElementsBenefits
