import React from 'react'
import { primaryColors, selectedColors } from 'src/design/design'
import styled from 'styled-components'
import ImageTemplate from './../../images/image-template.png'
import { ReactComponent as Close } from './../../images/close.svg'

const Container = styled.div`
  display: flex;
  flex: 0 1 800px;
  background: ${selectedColors.gray};

  @media (max-width: 768px) {
    border-radius: 5px;
    z-index: 4;
  }
`

const Holder = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: row;
  justify-content: space-around;
  padding: 50px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 30px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  background: ${selectedColors.gray};
  max-height: 275px;
  max-width: 270px;
`

const Image = styled.img`
  max-width: 400px;
  max-height: 500px;
  display: block;

  @media (max-width: 768px) {
    max-width: 300px;
    max-height: 300px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 30%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`
const TopDescription = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    display: none;
  }
`
const BottomDescription = styled.div`
  display: flex;
`

const Title = styled.h1`
  color: ${primaryColors.blue};
  font-size: 22px;
  font-weight: 700;

  @media (max-width: 768px) {
    display: none;
  }
`

const Text = styled.span`
  max-width: 600px;
  font-size: 14px;
`

const CloseButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
`

interface ModalProps {
  title: string | undefined
  text: string | undefined
  image?: string
  alt?: string
  handleClose: Function
}

function Modal({ title, text, image, handleClose, alt }: ModalProps) {
  return (
    <Container>
      <Holder>
        <ImageContainer style={{ maxHeight: title === 'Startup SC' ? 375 : 275 }}>
          {image ? (
            <Image src={image} alt={alt} />
          ) : (
            <Image src={ImageTemplate} alt='Template de imagem' />
          )}
        </ImageContainer>
        <DescriptionContainer>
          <TopDescription>
            <Title>{title}</Title>
          </TopDescription>
          <BottomDescription>
            <Text>{text}</Text>
          </BottomDescription>
        </DescriptionContainer>
      </Holder>
      <CloseButton onClick={() => handleClose()}>
        <Close width={22} height={22} />
      </CloseButton>
    </Container>
  )
}

export default Modal
