import React from 'react'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'
import WordChanging from '../reusable/wordChanging'
import { Button, OutlinedButton } from '../reusable/buttons'
import { ReactComponent as Facebook } from './../../images/facebook.svg'
import { ReactComponent as Instagram } from './../../images/instagram.svg'
import { ReactComponent as Linkedin } from './../../images/linkedin.svg'
import { ReactComponent as DownArrow } from './../../images/down-arrow.svg'
import { Link } from 'react-router-dom'

const Holder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const Info = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  gap: 80px;
  padding-left: 185px;
  margin-right: 35px;
  background-color: transparent;

  @media (max-width: 768px) {
    padding-left: 0;
    margin-right: 0;
    align-items: center;
    margin-top: 20px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Text = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: ${primaryColors.orange};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 26px;
  padding-top: 35px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const OrangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 768px) {
    width: 275px;
    height: 37px;
    font-size: 15px;
  }
`

const MobileButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 50px;

  @media (min-width: 768px) {
    display: none;
  }
`

const ButtonLink = styled.a`
  text-decoration: none;
`

const Links = styled(Link)`
  text-decoration: none;
`

const SocialMediaLink = styled.a``

const SocialMediaContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`

const ArrowHolder = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`

function Specialist() {
  return (
    <Holder>
      <Info>
        <Container>
          <Text>Seja bem-vindo a Prix Tech</Text>
          <Text>________</Text>
        </Container>
        <WordChanging />
        <ButtonContainer>
          <Links to={'/about'} title='Conheça a Prix'>
            <OutlinedButton>Conheça a Prix</OutlinedButton>
          </Links>
          <ButtonLink title='Fale Conosco' href='#Contact'>
            <Button>Fale Conosco</Button>
          </ButtonLink>
        </ButtonContainer>
        <MobileButtonContainer>
          <ButtonLink title='Fale Conosco' href='#Contact'>
            <OrangeButton>Fale Conosco</OrangeButton>
          </ButtonLink>
        </MobileButtonContainer>
        <SocialMediaContainer>
          <SocialMediaLink
            target='_blank'
            href='https://www.facebook.com/PrixTechSoftware'
            title='Ir para o Facebook da Prix.Tech (link externo)'
            rel='noopener noreferrer'
          >
            <Facebook width={45} height={45} />
          </SocialMediaLink>
          <SocialMediaLink
            target='_blank'
            href='https://www.instagram.com/prixtechsoftware/'
            title='Ir para o Instagram da Prix.Tech (link externo)'
            rel='noopener noreferrer'
          >
            <Instagram width={45} height={45} />
          </SocialMediaLink>
          <SocialMediaLink
            target='_blank'
            href='https://www.linkedin.com/company/prix-tech/'
            title='Ir para o LinkedIn da Prix.Tech (link externo)'
            rel='noopener noreferrer'
          >
            <Linkedin width={45} height={45} />
          </SocialMediaLink>
        </SocialMediaContainer>
        <ArrowHolder>
          <DownArrow width={74} height={24} />
        </ArrowHolder>
      </Info>
    </Holder>
  )
}

export default Specialist
