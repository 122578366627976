import React from 'react'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'
import PrizesScreen from '../home/prizesScreen'
import VideoOutlinedGreenCube from './../../images/cubesAndMarkers/video-green-outlined-cube.png'
import VideoFullOrangeCube from './../../images/cubesAndMarkers/video-full-orange-cube.png'
import VideoFullMediumOrangeOutlined from './../../images/cubesAndMarkers/video-full-medium-orange-outlined.png'
import VideoOutlinedSmallOrangeCube from './../../images/cubesAndMarkers/video-outlined-small-orange-cube.png'
import VideoMobileOutlinedGreenCube from './../../images/cubesAndMarkers/video-green-outlined-cube.png'
import VideoMobileFullOrangeCube from './../../images/cubesAndMarkers/video-full-orange-cube.png'
import VideoMobileFullMediumOrangeOutlined from './../../images/cubesAndMarkers/video-full-medium-orange-outlined.png'
import VideoMobileOutlinedSmallOrangeCube from './../../images/cubesAndMarkers/video-outlined-small-orange-cube.png'
import {
  VideoMobileFullMediumOrangeOutlinedImage,
  VideoMobileFullOrangeCubeImage,
  VideoMobileOutlinedGreenCubeImage,
  VideoMobileOutlinedSmallOrangeCubeImage,
} from './sectionScreenCubes'
import YouTube from 'react-youtube'
import { useMedia } from 'react-use'

const ImageOnTopContainer = styled.div`
  display: flex;
  flex: 1 0 50%;
  gap: 10px;
  flex-direction: row;
  background: #fff;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 180px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  max-height: 300px;
  max-width: 500px;
  margin: 100px;

  @media (max-width: 1500px) {
    margin: 20px;
  }

  @media (max-width: 768px) {
    margin: 10px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const TopDescription = styled.div`
  display: flex;
  padding: 30px 10%;
`

const Title = styled.h2`
  color: ${primaryColors.blue};
  font-size: 48px;
  font-weight: 700;

  @media (max-width: 850px) {
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10%;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const ButtonHolder = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 768px) {
    align-self: center;
  }
`

export const OutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
    width: 245px;
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
    width: 185px;
  }

  @media (max-width: 768px) {
    width: 285px;
    height: 45px;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

const VideoOutlinedGreenCubeImage = styled.img`
  position: relative;
  width: 124px;
  height: 105px;
  top: 340px;
  left: -60px;
  z-index: 3;

  @media (max-width: 1500px) {
    top: 280px;
    left: -60px;
    width: 100px;
    height: 80px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const VideoFullOrangeCubeImage = styled.img`
  position: relative;
  width: 77px;
  height: 91px;
  top: 350px;
  left: -720px;
  z-index: 1;

  @media (max-width: 1500px) {
    top: 280px;
    left: -645px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const VideoFullMediumOrangeOutlinedImage = styled.img`
  position: relative;
  width: 67px;
  height: 67px;
  top: -10px;
  left: -780px;
  z-index: 1;

  @media (max-width: 1500px) {
    top: -40px;
    left: -735px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const VideoOutlinedSmallOrangeCubeImage = styled.img`
  position: relative;
  width: 35px;
  height: 40px;
  top: -40px;
  left: -260px;
  z-index: 1;

  @media (max-width: 1500px) {
    top: -20px;
    left: -260px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const TopMobileCubeHolder = styled.div`
  position: relative;
  margin-top: -60px;

  @media (min-width: 768px) {
    display: none;
  }
`

const BottomMobileCubeHolder = styled.div`
  position: relative;

  @media (min-width: 768px) {
    display: none;
  }
`

function SectionScreen() {
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <>
      <ImageOnTopContainer style={{ marginTop: '50px' }}>
        <TopMobileCubeHolder>
          <VideoMobileFullMediumOrangeOutlinedImage
            src={VideoMobileFullMediumOrangeOutlined}
            alt='Cubo laranja sem preenchimento'
          />
          <VideoMobileOutlinedSmallOrangeCubeImage
            src={VideoMobileOutlinedSmallOrangeCube}
            alt='Cubo laranja pequeno sem preenchimento'
          />
        </TopMobileCubeHolder>
        <VideoContainer>
          <YouTube
            videoId='6eHMoqUrMe4'
            opts={{
              width: isMobile ? '350px' : '500px',
              height: '300px',
            }}
          />
          <VideoOutlinedGreenCubeImage
            src={VideoOutlinedGreenCube}
            alt='Cubo verde sem preenchimento'
          />
          <VideoFullOrangeCubeImage
            src={VideoFullOrangeCube}
            alt='Cubo laranja com preenchimento'
          />
          <VideoFullMediumOrangeOutlinedImage
            src={VideoFullMediumOrangeOutlined}
            alt='Cubo laranja sem preenchimento'
          />
          <VideoOutlinedSmallOrangeCubeImage
            src={VideoOutlinedSmallOrangeCube}
            alt='Cubo laranja pequeno sem preenchimento'
          />
        </VideoContainer>
        <BottomMobileCubeHolder>
          <VideoMobileOutlinedGreenCubeImage
            src={VideoMobileOutlinedGreenCube}
            alt='Cubo verde sem preenchimento'
          />
          <VideoMobileFullOrangeCubeImage
            src={VideoMobileFullOrangeCube}
            alt='Cubo laranja com preenchimento'
          />
        </BottomMobileCubeHolder>
        <DescriptionContainer>
          <TopDescription>
            <Title>Reconhecimento conquistado com dedicação e esforço</Title>
          </TopDescription>
          <BottomDescription>
            <Text>
              Os prêmios podem contribuir para o reconhecimento púbico a respeito da qualidade dos
              produtos desenvolvidos, da credibilidade da empresa, bem como do seu compromisso com
              os stakeholders. A participação, com vitória ou não, traz não só visibilidade e
              prestígio, mas também reconhecimento do mercado e, muitas vezes, lições que, se
              compreendidas, podem tornar a empresa ainda mais competitiva.
            </Text>
            <ButtonHolder>
              <LinkAct href={'/careers'} title={'Faça parte do nosso time'}>
                <OutlinedButton>Faça parte do nosso time</OutlinedButton>
              </LinkAct>
            </ButtonHolder>
          </BottomDescription>
        </DescriptionContainer>
      </ImageOnTopContainer>
      <PrizesScreen showVideoSection={false} />
    </>
  )
}

export default SectionScreen
