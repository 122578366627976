export const georeferencing = `
  Trabalhamos com mapas de alta qualidade e resolução para garantir o cadastro correto da geolocalização de cada cidadão.
  Com essa informação é possível fornecer dados detalhados, em mapas de calor ou georreferenciados para a Secretaria de Saúde.
`

export const publicManagement = `
  O prixNeuron traz para a palma da mão os principais dados sobre a saúde do município oportunizando
  às equipes de saúde informações para o processo de construção dos programas mais adequados às demandas dos cidadãos.
  O prixNeuron pode ser aplicado a toda a população, permitindo aos gestores de saúde terem um panorama geral sobre a
  situação da saúde em seu município, podendo também buscar informações específicas de cada cidadão.
`

export const healthConsultancy = `
  Com ampla experiência em integração com o e-SUS a Prix Tech está habilitada a prestar consultoria em saúde 
  e no sistema e-SUS, a fim mitigar erros operacionais e de cadastros e melhorar indicadores de desempenho e
  consequentemente melhorar a qualidade dos recursos que a saúde pode vir a receber.
`

export const citizenApp = `
  O Prix Saúde é um aplicativo gratuito que monitora diariamente a saúde do cidadão,
  a partir da resposta de um curto questionário, e melhora a comunicação dos cidadãos com a equipe de saúde do município.
  Com o aplicativo o cidadão pode comunicar instantaneamente a sua situação de saúde e as informações autorreferidas
  incrementam o histórico de saúde junto à Secretaria de Saúde. 
  Tais informações são relacionadas em espaços específicos da plataforma prixNeuron.
`

export const artificialIntelligence = `
  O prixNeuron possui inteligência artificial própria, a qual denominamos Ella,
  que quando aplicada a big data e cloud computing, geram uma estrutura informacional de extrema
  relevância para que os gestores da saúde pública tenham em suas mãos a visão necessária para dar a
  devida atenção à saúde básica em seu município. Possuímos depósito de patente desta inteligência artificial.
`
