import React from 'react'
import styled from 'styled-components'
import { ReactComponent as UpperChevron } from './../../images/upper-chevron.svg'
import { ReactComponent as TalkContact } from './../../images/talk-contact-blue.svg'
import { ReactComponent as EmailContact } from './../../images/email-contact-blue.svg'
import { ReactComponent as MarkerContact } from './../../images/marker-contact-blue.svg'
import { ReactComponent as Facebook } from './../../images/facebook-blue.svg'
import { ReactComponent as Instagram } from './../../images/instagram-blue.svg'
import { ReactComponent as Linkedin } from './../../images/linkedin-blue.svg'
import PrixLogo from './../../images/prix-logo-with-text.png'
import { primaryColors } from 'src/design/design'
import { selectedColors } from 'src/design/design'
import { Link } from 'react-router-dom'

const Container = styled.div`
  background-color: ${primaryColors.blue};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`

const Upper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`

const Baseboard = styled.p`
  color: ${primaryColors.orange};
`

const Bar = styled.hr`
  border-top: 1px solid;
  border-color: ${primaryColors.orange};
  width: 90%;
  margin-top: 32px;
  margin-bottom: 70px;

  @media (max-width: 768px) {
    width: 70%;
  }
`

const Logo = styled.img`
  display: flex;
  width: 50%;
  height: auto;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    padding-bottom: 0px;
    align-self: center;
    width: 60%;
  }
`

const CompanyInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 90%;
  gap: 70px;

  @media (max-width: 1120px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
  }
`

const BoxHolder = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`

const Text = styled.span`
  color: #fff;
  font-size: 14px;

  @media (max-width: 1120px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding-top: 32px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16px;
  }
`

const BoxTitle = styled.h1`
  font-weight: 700;
  color: ${selectedColors.blue};
  padding-bottom: 10px;
`

const Links = styled(Link)`
  color: #fff;
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`

const ImageWithTextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const PhoneNumberContactHolder = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 32px;
  gap: 13px;
`

const ContactHolder = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  gap: 13px;
`

const PhoneNumberHolder = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 21px;
`

const SocialMediaLink = styled.a``

const SocialMediaContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 46px;
  gap: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`

const MobileLinks = styled(Link)`
  color: ${selectedColors.blue};
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const ContactInfoHolder = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  gap: 60px;

  @media (max-width: 885px) {
    gap: 0;
    flex-direction: column;
  }
`

const TheTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

function FooterBar() {
  return (
    <Container>
      <Upper onClick={() => TheTop()}>
        <UpperChevron width={24} height={24} />
        <Baseboard>Voltar ao topo</Baseboard>
      </Upper>
      <Bar />
      <CompanyInfo>
        <ImageWithTextBox>
          <Logo src={PrixLogo} alt='Prix.Tech - Desenvolvimento Inteligente' />
          <Text>
            A Prix Tech desenvolve soluções de análise de dados baseados em georreferenciamento para
            entidades com foco em sustentabilidade social, ambiental e econômica. Juntos, produzimos
            com excelência para deixar um legado, melhorar a qualidade de vida das pessoas e
            contribuir de forma ativa com o desenvolvimento do país.
          </Text>
        </ImageWithTextBox>
        <BoxHolder>
          <Box>
            <BoxTitle>A Prix</BoxTitle>
            <Links to={'/about'} title='Quem somos'>
              Quem somos
            </Links>
            <Links to={'/about/believe'} title='No que acreditamos'>
              No que acreditamos
            </Links>
            <Links to={'/about/onu'} title='Objetivos sustentáveis'>
              Objetivos sustentáveis
            </Links>
          </Box>
          <Box>
            <BoxTitle>Prêmios</BoxTitle>
            <Links to={'/prizes/StemmerPrize'} title='Prêmios Stemmer'>
              Prêmios Stemmer
            </Links>
            <Links to={'/prizes/MCTIPrize'} title='IA2 MCTI'>
              IA2 MCTI
            </Links>
            <Links to={'/prizes/BoasPraticasPrize'} title='Prêmio Boas Práticas'>
              Prêmio Boas Práticas
            </Links>
            <Links to={'/prizes/StartupSCPrize'} title='Startup SC'>
              Startup SC
            </Links>
          </Box>
        </BoxHolder>
        <BoxHolder>
          <Box>
            <BoxTitle>Inteligência Digital e Neuron</BoxTitle>
            <Links to={'/artificialIntelligence/Intelligence'} title='Inteligência Artificial'>
              Soluções em negócios
            </Links>
            <Links to={'/artificialIntelligence/Georeferencing'} title='Georreferenciamento'>
              Georreferenciamento
            </Links>
            <Links to={'/neuron'} title='Gestão Pública'>
              Gestão Pública
            </Links>
            <Links to={'/neuron/agility'} title='App Cidadão'>
              App Cidadão
            </Links>
            <Links to={'/neuron/publicManagement'} title='Consultoria E-SUS'>
              Consultoria E-SUS
            </Links>
          </Box>
          <Box>
            <BoxTitle>Carreiras</BoxTitle>
            <Links to={'/careers'} title='Trabalhe conosco'>
              Trabalhe Conosco
            </Links>
          </Box>
        </BoxHolder>
        <ContactInfoHolder>
          <BoxHolder>
            <BoxTitle>FALE CONOSCO</BoxTitle>
            <ContactHolder>
              <EmailContact width={24} height={24} />
              <Links to={'mailto: contato@prix.tech'} title='E-mail de contato'>
                contato@prix.tech
              </Links>
            </ContactHolder>
            <PhoneNumberContactHolder>
              <TalkContact width={24} height={24} />
              <PhoneNumberHolder>
                <Text>49 99921.6226 &#40;CEO&#41;</Text>
                <Text>49 99919.0267 &#40;CTO&#41;</Text>
              </PhoneNumberHolder>
            </PhoneNumberContactHolder>
          </BoxHolder>
          <BoxHolder>
            <BoxTitle>ONDE ESTAMOS</BoxTitle>
            <ContactHolder>
              <MarkerContact width={24} height={24} />
              <Text>Luzerna • SC • Brasil</Text>
            </ContactHolder>
            <ContactHolder>
              <SocialMediaContainer>
                <SocialMediaLink
                  target='_blank'
                  href='https://www.facebook.com/PrixTechSoftware'
                  title='Ir para o Facebook da Prix.Tech (link externo)'
                  rel='noopener noreferrer'
                >
                  <Facebook width={32} height={32} />
                </SocialMediaLink>
                <SocialMediaLink
                  target='_blank'
                  href='https://www.instagram.com/prixtechsoftware/'
                  title='Ir para o Instagram da Prix.Tech (link externo)'
                  rel='noopener noreferrer'
                >
                  <Instagram width={32} height={32} />
                </SocialMediaLink>
                <SocialMediaLink
                  target='_blank'
                  href='https://www.linkedin.com/company/prix-tech/'
                  title='Ir para o LinkedIn da Prix.Tech (link externo)'
                  rel='noopener noreferrer'
                >
                  <Linkedin width={32} height={32} />
                </SocialMediaLink>
              </SocialMediaContainer>
            </ContactHolder>
          </BoxHolder>
        </ContactInfoHolder>
        <MobileColumn>
          <MobileLinks to={'/about'} title='Quem somos'>
            Quem somos
          </MobileLinks>
          <MobileLinks to={'/artificialIntelligence'} title='Tecnologia'>
            Soluções em negócios
          </MobileLinks>
          <MobileLinks to={'/neuron'} title='Saúde'>
            Soluções em saúde
          </MobileLinks>
          <MobileLinks to={'/prizes'} title='Prêmios'>
            Prêmios
          </MobileLinks>
          <MobileLinks to={'/careers'} title='Carreiras'>
            Carreiras
          </MobileLinks>
        </MobileColumn>
      </CompanyInfo>
    </Container>
  )
}

export default FooterBar
