import React from 'react'
import { lightVariationColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import GreenCube from './../../images/cubesAndMarkers/green_cube.png'
import OrangeHalfCube from './../../images/cubesAndMarkers/half_big_orange_cube.png'
import OrangeCube from './../../images/cubesAndMarkers/orange_cube_last.png'
import OrangeMarker from './../../images/cubesAndMarkers/outlined_orange_marker.png'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${lightVariationColors.blue};
  height: auto;
`

const Title = styled.h1`
  color: ${primaryColors.orange};
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding-left: 500px;
  padding-right: 500px;
  margin-bottom: 220px;
  margin-top: 100px;

  @media (max-width: 1500px) {
    padding-left: 250px;
    padding-right: 250px;
  }

  @media (max-width: 950px) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 40px;
    margin-top: 95px;
  }
`

const Elements = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  gap: 13px 150px;
  text-align: left;
  margin-bottom: 70px;
  margin-right: 235px;
  margin-left: 235px;

  @media (max-width: 1100px) {
    gap: 13px 35px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 20px;
  }
`

const Element = styled.div``

const ElementText = styled.p`
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
  }
`

const GreenCubeImage = styled.img`
  width: 60px;
  height: 67px;
  margin-top: -40px;
  margin-left: 480px;

  @media (max-width: 768px) {
    margin-left: 0;
    display: flex;
    align-self: flex-end;
  }
`

const OrangeCubeImage = styled.img`
  display: flex;
  align-self: flex-end;
  margin-right: 120px;
  width: 145px;
  height: 116px;
  margin-top: -90px;
  margin-left: 480px;

  @media (max-width: 768px) {
    display: none;
  }
`

const MobileOrangeCubeImage = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 110px;
    height: 96px;
    display: flex;
    align-self: flex-start;
    margin-bottom: 40px;
  }
`

const MarkerImage = styled.img`
  display: flex;
  align-self: flex-start;
  width: 90px;
  height: 100px;
  margin-left: 210px;
  transform: rotate(22deg);
  margin-bottom: 170px;

  @media (max-width: 768px) {
    display: none;
  }
`

function WhiteLabel() {
  return (
    <Section>
      <GreenCubeImage src={GreenCube} alt='Cubo verde' />
      <OrangeCubeImage src={OrangeCube} alt='Cubo não preenchido laranja' />
      <Title>
        O Prix Empresas se baseia em consultoria em tempo real por meio de software gerando
        informações sobre os dados do seu nicho. Você pode aplicá-la onde sua imaginação e sua
        necessidade permitirem
      </Title>
      <Elements>
        <Element>
          <ElementText>• Software de consultoria em tempo real.</ElementText>
        </Element>
        <Element>
          <ElementText>
            • Simplifique a busca por informações e ganhe mais tempo para focar no que realmente
            importa.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Tenha as informações do seu nicho sempre na palma da mão e proporcione uma experiência
            mais individualizada.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Centralize e condense as informações de demandas do seu negócio.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Organização, informação e segurança em cada dado. Isso reflete a qualidade do seu
            trabalho.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Informações claras e de simples acesso para organizar a massividade de dados
            sensíveis.
          </ElementText>
        </Element>
        <Element>
          <ElementText>• A plataforma é segura, clara e fácil de utilizar.</ElementText>
        </Element>
        <Element>
          <ElementText>
            • Compilação das informações do seu negócio em uma única plataforma, sem retrabalho.
          </ElementText>
        </Element>
      </Elements>
      <MarkerImage src={OrangeMarker} alt='Marcador de coordenadas laranja' />
      <MobileOrangeCubeImage src={OrangeHalfCube} alt='Cubo não preenchido laranja' />
    </Section>
  )
}

export default WhiteLabel
