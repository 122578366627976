import { primaryColors } from 'src/design/design'
import styled from 'styled-components'

export const OutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 38px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    /* width: 80%; */
    height: 45px;
  }

  @media (max-width: 768px) {
    width: 145px;
    height: 45px;
  }
`

export const MediumOutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 245px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    width: 90%;
    height: 35px;
    font-size: 14px;
  }
`

export const LargeOutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    width: 90%;
    height: 65px;
    font-size: 14px;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 38px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  font-weight: 14px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 45px;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
`
