import React from 'react'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import DesktopElementsBenefits from './benefitsComponents/desktopElements'
import BenefitsMobileElements from './benefitsComponents/mobileElements'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 20px;
    justify-content: flex-start;
  }
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: #000;
  line-height: 48px;

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 38px;
  }
`

function Benefits() {
  const isMobile = useMedia('(max-width: 960px)')

  return (
    <Section>
      <Container>
        <Title>Alguns de nossos benefícios</Title>
      </Container>
      {isMobile ? <BenefitsMobileElements /> : <DesktopElementsBenefits />}
    </Section>
  )
}

export default Benefits
