export const stemmerPrize = `
A Prix Tech recebeu o maior prêmio de inovação de Santa Catarina. Trata-se do Prêmio Inovação Catarinense - Professor Caspar Erich Stemmer na categoria Inovação em Processo ou Serviço com a plataforma prixNeuron em 2019. 

A premiação homenageia a memória do Professor Caspar Erich Stemmer, personalidade catarinense de destaque nacional no desenvolvimento da ciência, da tecnologia e da inovação e é promovido pela Fundação Amparo à Pesquisa e Inovação do Estado de Santa Catarina. O Prêmio Inovação Catarinense foi criado pela lei 14.328, de 2008, a Lei Catarinense de Inovação.

A intenção com o Prêmio Stemmer é estimular estudantes, professores, pesquisadores e inventores independentes, bem como empresas, instituições e órgãos públicos e reconhecer a importância dos vencedores no auxílio ao desenvolvimento dos ecossistemas de empreendedorismo catarinense.
`

export const mctiPrize = `
A plataforma prixNeuron foi classificada no programa de aceleração do Ministério da Ciência, Tecnologia e Inovação - IA² MCTI – que objetiva apoiar projetos de pesquisa e desenvolvimento orientados ao desenvolvimento de soluções em Inteligência Artificial. 
O programa foi realizado em 2020, com abrangência nacional e criou importantes oportunidades para apoiar investimentos em projetos de Planejamento & Desenvolvimento.
`

export const boasPraticasPrize = `
Em 2020 a Prix Tech foi finalista da 3ª edição do Prêmio Boas Práticas em Gestão Pública de Santa Catarina promovido pela UDESC, sendo premiada em 2021 como a segunda colocada na categoria Organizações da Sociedade Civil, Empresas e Profissionais. 
A empresa concorreu com a prática prixNeuron, gestão e inteligência na saúde.
A prática venceu na modalidade Gestão da Saúde Pública que engloba práticas de transparência com cidadania que aprimoram a qualidade da gestão em unidades de saúde, no sistema de saúde, na promoção de saúde e/ou na provisão de serviços públicos em saúde.
O Prêmio de Boas Práticas em Gestão Pública de Santa Catarina é uma iniciativa do Departamento de Administração Pública do Centro de Ciências da Administração e Socioeconômicas da Universidade do Estado de Santa Catarina, tendo como finalidade identificar, reconhecer, estimular e premiar práticas inovadoras de gestão pública, sendo esta edição realizada pela Rede de Controle da Gestão Pública no Estado de Santa Catarina e apoiadores.
`

export const startupSCPrize = `
Em 2021 a empresa esteve entre as 50 empresas escolhidas para fazerem parte da 11º turma do Programa de Capacitação Startup SC em 2021, realizado pelo SEBRAE com apoio da Fapesc. As startups participaram de mentorias sobre diversos temas, como pitch, marketing digital, gestão financeira e de pessoas, todas ministradas por especialistas do mercado. 

O projeto Startup SC é uma iniciativa do  Sebrae Santa Catarina que visa desenvolver e promover empreendimentos inovadores em todo o estado de Santa Catarina. O principal objetivo do projeto é fortalecer as startups digitais a partir da difusão da cultura empreendedora e da profissionalização da gestão de seus empreendimentos com ações de capacitação, inovação e mercado. 

A Prix Tech ficou entre as 25 empresas com melhor desempenho, entre as participantes do projeto, e recebeu aporte financeiro para aceleração.

`
