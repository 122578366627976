import React, { useEffect } from 'react'
import Prixter from './prixter'
import NavBar from '../reusable/navbar'
import Cover from '../reusable/cover'
import Benefits from './benefits'
import Footer from '../reusable/footer'
import OpenPositions from './openPositions'
import ScrollToTop from '../reusable/scrollToTop'
import { useParams } from 'react-router'
import { scroller } from 'react-scroll'

function HomeCareers() {
  const { sectionId } = useParams()

  useEffect(() => {
    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: true,
      })
    }
  }, [sectionId])

  return (
    <>
      <ScrollToTop />
      <Cover title={'Carreiras'} />
      <Prixter />
      <Benefits />
      <OpenPositions />
      <Footer />
      <NavBar changeBackground />
    </>
  )
}

export default HomeCareers
