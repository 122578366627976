import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import { useMedia } from 'react-use'

const Container = styled.div`
  display: flex;
  flex: 1 0 50%;
  gap: 10px;
  flex-direction: row;
  background: #fff;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 100px;
  margin-left: 200px;
  margin-right: 200px;

  @media (max-width: 1400px) {
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (max-width: 1100px) {
    margin-left: 10px;
    margin-right: 10px;
    gap: 5px;
  }

  @media (max-width: 768px) {
    margin-left: 200px;
    margin-right: 200px;
  }
`

const ImageOnTopContainer = styled.div`
  display: flex;
  flex: 1 0 50%;
  gap: 10px;
  flex-direction: row;
  background: #fff;
  justify-content: center;
  align-items: center;
  align-self: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const TextFrame = styled.div`
  @media (max-width: 768px) {
    padding: 5px 10%;
  }
`

const TopDescription = styled.div`
  display: flex;
  padding: 5px 10%;

  @media (max-width: 768px) {
    padding: 0;
  }
`

const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10%;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 0;
  }
`

const Title = styled.h2`
  color: ${primaryColors.blue};
  font-size: 48px;
  font-weight: 700;
  line-height: 1em;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const ButtonHolder = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 768px) {
    align-self: center;
  }
`

export const OutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 285px;
    height: 45px;
  }
`

export const OrangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 315px;
    height: 35px;
    font-size: 15px;
  }
`

const Video = styled.video`
  object-fit: cover;
  margin: 200px;
  width: 600px;
  height: 400px;

  @media (max-width: 1500px) {
    width: 500px;
    height: 300px;
    margin: 100px;
  }

  @media (max-width: 1150px) {
    width: 400px;
    height: 200px;
    margin: 20px;
  }

  @media (max-width: 768px) {
    margin: 0;
    width: 320px;
    height: 240px;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

interface MockProps {
  title: string | undefined
  text?: string | undefined
  image?: string
  video?: string
  hideImageOnMobile?: boolean
  imageOnTopMobile?: boolean
  rowReverse?: boolean
  largeOrangeButton?: boolean
  buttonText?: string | undefined
  children?: JSX.Element
  titleColor?: string
  link?: string
  target?: string
  imageAlt?: string
  linkTitle?: string
}

function Mocker({
  title,
  text,
  image,
  video,
  hideImageOnMobile,
  imageOnTopMobile,
  rowReverse,
  largeOrangeButton,
  buttonText,
  children,
  titleColor,
  link,
  target,
  imageAlt,
  linkTitle,
}: MockProps) {
  const isMobile = useMedia('(max-width: 768px)')

  return isMobile && imageOnTopMobile ? (
    <ImageOnTopContainer style={{ marginTop: '50px' }}>
      {hideImageOnMobile && isMobile ? null : (
        <ImageContainer>
          <Image src={image} alt={imageAlt} />
        </ImageContainer>
      )}
      {video ? (
        <Video width='500' height='300' controls>
          <source src={video} />
        </Video>
      ) : null}
      <TextFrame>
        <DescriptionContainer>
          <TopDescription>
            <Title style={{ color: titleColor ? titleColor : primaryColors.blue }}>{title}</Title>
          </TopDescription>
          <BottomDescription>
            {children ? children : <Text>{text}</Text>}
            {buttonText ? (
              <ButtonHolder>
                <LinkAct target={target} href={link} title={linkTitle}>
                  <OutlinedButton>{buttonText}</OutlinedButton>
                </LinkAct>
              </ButtonHolder>
            ) : null}
          </BottomDescription>
        </DescriptionContainer>
      </TextFrame>
    </ImageOnTopContainer>
  ) : (
    <Container style={{ flexDirection: rowReverse ? 'row-reverse' : 'row' }}>
      {(hideImageOnMobile && isMobile) || video ? null : (
        <ImageContainer>
          <Image src={image} alt={imageAlt} />
        </ImageContainer>
      )}
      {video ? (
        <Video width='500' height='300' controls>
          <source src={video} />
        </Video>
      ) : null}
      <DescriptionContainer>
        <TopDescription>
          <Title>{title}</Title>
        </TopDescription>
        <BottomDescription>
          {children ? children : <Text>{text}</Text>}
          <ButtonHolder>
            {buttonText ? (
              largeOrangeButton ? (
                <LinkAct target={target} href={link} title={linkTitle}>
                  <OrangeButton>{buttonText}</OrangeButton>
                </LinkAct>
              ) : (
                <LinkAct target={target} href={link} title={linkTitle}>
                  <OutlinedButton>{buttonText}</OutlinedButton>
                </LinkAct>
              )
            ) : null}
          </ButtonHolder>
        </BottomDescription>
      </DescriptionContainer>
    </Container>
  )
}

export default Mocker
