export const foodStamps = `
  Esse benefício é concedido por meio de um cartão flex - Vale refeição e também Vale alimentação, aceito em restaurantes,
  supermercados, padarias, lanchonetes.
  Todos os estabelecimentos abrangidos podem ser consultados por meio de um app específico.
`

export const onboardKit = `
  Para ajudar a tornar o seu escritório em casa o mais parecido com o que teríamos em um escritório físico da
  Prix Tech fornecemos os equipamentos para você desenvolver seu trabalho da melhor forma.
`

export const referralBonus = `
  Encorajamos e reconhecemos as indicações com um pagamento de bônus para cada pessoa indicada que é contratada.
`

export const serviceTimeBonus = `
  Reconhecemos a dedicação dos nossos colaboradores e premiamos eles por tempo de casa. 
`

export const maternityPaternityLeave = `
  Oferecemos 120 dias de licença-maternidade e cinco dias de licença-paternidade.
`

export const healthInsurance = `
  Oferecemos plano de saúde Unimed com cobertura nacional e acomodação individual para Prixter,
  sendo possível contratá-lo para dependentes.
`

export const lifeInsurance = `
  Todos os colaboradores da Prix Tech estão cobertos por seguro de vida.
`
