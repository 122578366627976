import React from 'react'
import { useMedia } from 'react-use'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import MobileElements from './ourSolutionsComponents/mobileElements'
import DesktopElements from './ourSolutionsComponents/desktopElements'

const Section = styled.section`
  background-color: #fff;
  height: auto;
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: ${primaryColors.blue};
  line-height: 44px;
  text-align: center;
  margin-top: 185px;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-top: 120px;
    margin-bottom: 60px;
    font-size: 35px;
    line-height: 34px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
  }
`

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 120px;
  gap: 38px;
  margin-left: 430px;
  margin-right: 430px;

  @media (max-width: 1600px) {
    margin-left: 350px;
    margin-right: 350px;
  }

  @media (max-width: 1450px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 1150px) {
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 50px;
    margin-right: 50px;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${primaryColors.gray};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const IDHolder = styled.div``

function OurSolutions() {
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <Section id={'ourSolution'}>
      <IDHolder id={'Georeferencing'} />
      <IDHolder id={'Intelligence'} />
      <Title>
        Nossas soluções modulares são adaptadas especialmente para você e suas necessidades
      </Title>
      <TextHolder>
        <Text>
          Nossa solução empresarial é constituída de diversos módulos, tornando assim o Prix
          Empresas um produto personalizado e pensado especificamente para suas necessidades.
        </Text>
        <Text>
          Baseado em banco de dados, no Prix Empresas é possível criar filtros diversos que auxiliem
          na análise das informações relevantes de cada negócio. É possível também cruzar tais
          bancos de dados com bancos de dados públicos podendo assim ampliar as análises."
        </Text>
      </TextHolder>
      {isMobile ? <MobileElements /> : <DesktopElements />}
    </Section>
  )
}

export default OurSolutions
