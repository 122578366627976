import React from 'react'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'
import SmartphoneGlobe from './../../images/smartphoneGlobe.png'
import OrangeCube from './../../images/cubesAndMarkers/orange_cube.png'
import ComputerMap from './../../images/computer-map.png'
import { ReactComponent as SebraeLogo } from './../../images/sebrae-logo.svg'
import { ReactComponent as InovaleLogo } from './../../images/inovale-logo.svg'
import { ReactComponent as StartupSCLogo } from './../../images/startupSC-logo.svg'
import { ReactComponent as AbstartupsLogo } from './../../images/abstartups-logo.svg'
import Mocker from '../reusable/mocker'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #ffffff;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const NeuronWord = styled.span`
  font-weight: bold;
  font-style: italic;
`

const Strong = styled.span`
  font-weight: bold;
`

const PartnerTitle = styled.h2`
  font-weight: 700;
  font-size: 38px;
  color: ${primaryColors.blue};
  padding-right: 80px;

  @media (max-width: 1200px) {
    font-size: 28px;
  }
`

const OurPartnersInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 70px;
  padding: 120px 60px;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`

const CubeImage = styled.img`
  width: 75px;
  height: 88px;
  margin-left: 82px;

  @media (max-width: 768px) {
    display: none;
  }
`

function CompanyScreen() {
  return (
    <Section>
      <Mocker
        title='Juntos construímos um legado que transforma o país.'
        buttonText='Conheça a Prix Tech'
        link={'/about'}
        linkTitle='Conheça a Prix Tech'
        image={SmartphoneGlobe}
        imageAlt='Image de um smartphone com um mockup de uma solução white label com um globo terrestre cobrindo parte da tela'
        hideImageOnMobile={true}
        rowReverse={false}
        imageOnTopMobile={false}
        children={
          <>
            <Text>
              Somos a Prix Tech, um time plural com um propósito claro, contribuir de forma ativa
              com o desenvolvimento do país e melhorar a qualidade de vida das pessoas, por isso
              criamos com excelência para construir um <Strong>legado</Strong> positivo, palpável e
              transformador.
            </Text>
            <Text>
              Desenvolvemos soluções de análise de dados baseados em{' '}
              <Strong>georreferenciamento</Strong> para entidades com foco em sustentabilidade
              social, ambiental e econômica.
            </Text>
          </>
        }
      />
      <OurPartnersInfo>
        <PartnerTitle>Nossos programas e parceiros</PartnerTitle>
        <SebraeLogo width={128} height={128} />
        <AbstartupsLogo width={158} height={128} />
        <StartupSCLogo width={158} height={128} />
        <InovaleLogo width={128} height={128} />
      </OurPartnersInfo>
      <Container>
        <CubeImage src={OrangeCube} alt='Cubo laranja' />
        <Mocker
          title='Monitoramento e análise de dados georreferenciados.'
          buttonText='Conheça nossas soluções'
          link={'/artificialIntelligence'}
          linkTitle='Conheça nossas soluções'
          image={ComputerMap}
          imageAlt='Imagem de um mockup com um computador mostrando uma das soluções white label da Prix.Tech'
          hideImageOnMobile={false}
          imageOnTopMobile
          rowReverse={true}
          children={
            <Text>
              O <NeuronWord>Neuron®</NeuronWord> é uma tecnologia patenteada da Prix Tech que
              combina recursos avançados de georreferenciamento, bancos de dados e inteligência
              artificial para realizar o monitoramento e análise de dados.
            </Text>
          }
        />
      </Container>
    </Section>
  )
}

export default CompanyScreen
