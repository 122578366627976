import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const TopDescription = styled.div`
  display: flex;
  padding: 30px 10%;
`

const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10%;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const Title = styled.h2`
  color: #000;
  font-size: 2.75em;
  font-weight: 700;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 14 px;
  }
`

export const OutlinedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: ${primaryColors.orange};
  transition: all 0.15s;
  appearance: button;
  background-color: transparent;
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 285px;
    height: 45px;
  }
`

export const OrangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 315px;
    height: 35px;
    font-size: 15px;
  }
`

function Interest() {
  return (
    <Container>
      <DescriptionContainer>
        <TopDescription>
          <Title>Tem interesse em fazer parte do time Prix?</Title>
        </TopDescription>
        <BottomDescription>
          <Text>
            Que tal ser um Prixter e fazer parte desse super time? Nos envie um email contanto um
            pouco sobre você, o que gosta de fazer, quais suas habilidades e principalmente, não
            deixe de enviar seu currículo e portfólio se tiver, isso ajudara muito em nossas futuras
            conversas ;&#41;.
          </Text>
        </BottomDescription>
      </DescriptionContainer>
    </Container>
  )
}

export default Interest
