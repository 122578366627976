import React, { useState } from 'react'
import { complementaryColors, primaryColors, selectedColors } from 'src/design/design'
import styled from 'styled-components'
import prixLogoWhite from './../../images/logo-white.png'
import prixLogoBlue from './../../images/logo-blue.png'
import { ReactComponent as HamburgerMenu } from './../../images/hamburger-menu.svg'
import { Button, OutlinedButton } from './buttons'
import { NavLink, useLocation } from 'react-router-dom'
import HomeNavMobileMenu from './menu/mobileMenu'

const Container = styled.section`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;
`

const Nav = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  background-image: -o-linear-gradient(
    90deg,
    ${complementaryColors.crowBlackBlue} 38.98%,
    ${complementaryColors.armorWash} 65.66%
  );
  background-image: -moz-linear-gradient(
    90deg,
    ${complementaryColors.crowBlackBlue} 38.98%,
    ${complementaryColors.armorWash} 65.66%
  );
  background-image: -ms-linear-gradient(
    90deg,
    ${complementaryColors.crowBlackBlue} 38.98%,
    ${complementaryColors.armorWash} 65.66%
  );
  background-image: linear-gradient(
    90deg,
    ${complementaryColors.crowBlackBlue} 38.98%,
    ${complementaryColors.armorWash} 65.66%
  );
`

const NavWhite = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  background-color: #fff;
  z-index: 7;
`

const Logo = styled.img`
  width: 100px;
  height: 25px;
`

const SiteContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media (max-width: 1130px) {
    display: none;
  }
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding-right: 25px;

  @media (max-width: 1130px) {
    display: none;
  }
`

const SiteLinks = styled(NavLink)`
  color: ${selectedColors.gray};
  font-weight: 500;
  size: 12px;
  line-height: 21px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transition: 0.7s;
    color: ${primaryColors.orange};
  }

  /* &.active {
    color: ${primaryColors.orange};
  } */
`

const BlueSiteLinks = styled(NavLink)`
  color: ${primaryColors.blue};
  font-weight: 500;
  size: 12px;
  line-height: 21px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transition: 0.7s;
    color: ${primaryColors.orange};
  }

  &.active {
    color: ${primaryColors.orange};
  }
`

const MobileButtonsDisplay = styled.div`
  display: none;

  @media (max-width: 1130px) {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding-right: 25px;
  }
`

const ImageLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  object-fit: fill;
  margin-left: 35px;

  @media (max-width: 1130px) {
    padding-left: 0;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

const HamburgerButton = styled.button`
  text-decoration: none;
  background: none;
  color: inherit;
  border: none;
`

interface NavBarProps {
  changeBackground: boolean
}

function NavBar({ changeBackground }: NavBarProps) {
  const [menuIsVisible, setMenuIsVisible] = useState(false)

  const location = useLocation()

  const handleOpen = (event: React.MouseEvent) => {
    event.preventDefault()
    setMenuIsVisible(true)
  }
  const handleClose = () => setMenuIsVisible(false)

  const handleTop = (to: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (to === location.pathname) {
      event.preventDefault()
      event.stopPropagation()
      window.scrollTo(0, 0)
    }
  }

  return (
    <Container>
      {changeBackground ? (
        <NavWhite>
          <ImageLink to={'/'}>
            <Logo src={prixLogoBlue} alt={'Logo da Prix.Tech'} />
          </ImageLink>
          <SiteContent>
            <BlueSiteLinks to={'/'} onClick={event => handleTop('/', event)}>
              Início
            </BlueSiteLinks>
            <BlueSiteLinks to={'/about'} onClick={event => handleTop('/about', event)}>
              Quem somos
            </BlueSiteLinks>
            <BlueSiteLinks
              to={'/artificialIntelligence'}
              onClick={event => handleTop('/artificialIntelligence', event)}
            >
              Soluções em negócios
            </BlueSiteLinks>
            <BlueSiteLinks to={'/neuron'} onClick={event => handleTop('/neuron', event)}>
              Solução em saúde
            </BlueSiteLinks>
            <BlueSiteLinks to={'/prizes'} onClick={event => handleTop('/prizes', event)}>
              Premiações
            </BlueSiteLinks>
          </SiteContent>
          <ButtonContent>
            <SiteLinks
              to={'/careers'}
              onClick={event => handleTop('/careers', event)}
              title='Trabalhe conosco'
            >
              <OutlinedButton>Trabalhe conosco</OutlinedButton>
            </SiteLinks>
            <LinkAct href={'#Contact'} title='Fale conosco'>
              <Button>Fale conosco</Button>
            </LinkAct>
          </ButtonContent>
          <MobileButtonsDisplay>
            <HamburgerButton onClick={handleOpen} title='Botão do menu'>
              <HamburgerMenu fill={primaryColors.orange} width={32} height={32} />
            </HamburgerButton>
          </MobileButtonsDisplay>
          <HomeNavMobileMenu isVisible={menuIsVisible} onClose={handleClose} />
        </NavWhite>
      ) : (
        <Nav>
          <ImageLink to={'/'}>
            <Logo src={prixLogoWhite} alt={'Logo da Prix.Tech'} />
          </ImageLink>
          <SiteContent>
            <SiteLinks to={'/'} onClick={event => handleTop('/', event)}>
              Início
            </SiteLinks>
            <SiteLinks to={'/about'} onClick={event => handleTop('/about', event)}>
              Quem somos
            </SiteLinks>
            <SiteLinks
              to={'/artificialIntelligence'}
              onClick={event => handleTop('/artificialIntelligence', event)}
            >
              Soluções em negócios
            </SiteLinks>
            <SiteLinks to={'/neuron'} onClick={event => handleTop('/neuron', event)}>
              Solução em saúde
            </SiteLinks>
            <SiteLinks to={'/prizes'} onClick={event => handleTop('/prizes', event)}>
              Premiações
            </SiteLinks>
          </SiteContent>
          <ButtonContent>
            <SiteLinks to={'/careers'} title='Trabalhe conosco'>
              <OutlinedButton>Trabalhe conosco</OutlinedButton>
            </SiteLinks>
            <LinkAct href={'#Contact'} title='Fale conosco'>
              <Button>Fale conosco</Button>
            </LinkAct>
          </ButtonContent>
          <MobileButtonsDisplay>
            <HamburgerButton onClick={handleOpen} title='Botão do menu'>
              <HamburgerMenu fill={primaryColors.orange} width={32} height={32} />
            </HamburgerButton>
          </MobileButtonsDisplay>
          <HomeNavMobileMenu isVisible={menuIsVisible} onClose={handleClose} />
        </Nav>
      )}
    </Container>
  )
}

export default NavBar
