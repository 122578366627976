import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { initGA, logPageView } from './analytics'
import { useLocation } from 'react-router-dom'

initGA()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const TrackRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    logPageView(location.pathname)
  }, [location])

  return null
}

root.render(
  <BrowserRouter>
    <App />
    <TrackRoutes />
  </BrowserRouter>,
)
