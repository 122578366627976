import React, { useEffect } from 'react'
import NavBar from '../reusable/navbar'
import Footer from '../reusable/footer'
import Cover from '../reusable/cover'
import SectionScreen from './sectionScreen'
import ScrollToTop from '../reusable/scrollToTop'
import { useParams } from 'react-router'
import { scroller } from 'react-scroll'

function HomePrizes() {
  const { sectionId } = useParams()

  useEffect(() => {
    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: true,
      })
    }
  }, [sectionId])

  return (
    <>
      <ScrollToTop />
      <Cover title={'Prêmios'} />
      <SectionScreen />
      <Footer />
      <NavBar changeBackground />
    </>
  )
}

export default HomePrizes
