import React from 'react'
import Mocker from '../reusable/mocker'
import Prixters from './../../images/colaboradores.png'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 14 px;
  }
`

function Prixter() {
  return (
    <Mocker
      title='Faça parte do time Prix, seja um Prixter'
      image={Prixters}
      imageAlt={'Imagem com os avatares dos colaboradores da Prix.Tech'}
      imageOnTopMobile
      children={
        <>
          <Text>
            Na Prix Tech você tem a oportunidade de trabalhar em um ambiente dinâmico e fazer parte
            de uma equipe que busca atualização constante.
          </Text>
          <Text>
            Oferecemos benefícios pensados para apoiar seu desenvolvimento pessoal e profissional,
            bem como suas necessidades de saúde e bem-estar. Os pacotes de benefícios são revisados
            periodicamente para fornecer a melhor experiência de trabalho.
          </Text>
        </>
      }
    />
  )
}

export default Prixter
