import React, { useState } from 'react'
import { useMedia } from 'react-use'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import InnovationPrizeCatarinense from './../../images/innovation-prize-catarinense.png'
import { ReactComponent as McticPrize } from './../../images/mctic-prize.svg'
import Modal from '../reusable/modal'
import { boasPraticasPrize, mctiPrize, startupSCPrize, stemmerPrize } from '../reusable/prizesText'
import SmallerOutlinedOrangeCube from './../../images/cubesAndMarkers/smaller_outlined_orange_cube.png'
import MediumOutlinedOrangeCube from './../../images/cubesAndMarkers/medium_outlined_orange_cube.png'
import RightHalfSmallCube from './../../images/cubesAndMarkers/right-half-small-cube.png'
import GreenMarkerOutlined from './../../images/cubesAndMarkers/green_marker_outlined.png'
import PrizeStartupSC from './../../images/prize_Startup_SC.jpg'
import PrizeBoasPraticas from './../../images/prize_boas_praticas.jpg'
import PrizeStemmer from './../../images/prize_stemmer.jpg'
import PrizeIa2mcti from './../../images/ia2mcti.png'
import { ReactComponent as StartupSCLogo } from './../../images/startupSC-logo.svg'
import StartupSCLogoImage from './../../images/startupSC-logo.png'
import YouTube from 'react-youtube'

const Section = styled.section`
  height: auto;
  background-color: #fff;
`

const Holder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: ${primaryColors.blue};
  padding-bottom: 40px;
  text-align: center;
  margin-left: 640px;
  margin-right: 640px;

  @media (max-width: 1700px) {
    margin-left: 550px;
    margin-right: 550px;
  }

  @media (max-width: 1600px) {
    margin-left: 450px;
    margin-right: 450px;
  }

  @media (max-width: 1400px) {
    margin-left: 250px;
    margin-right: 250px;
  }

  @media (max-width: 1100px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 32px;
    text-align: left;
  }
`

const PrizeTitle = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: ${primaryColors.blue};
  padding-bottom: 40px;
  text-align: center;
  margin-left: 500px;
  margin-right: 500px;

  @media (max-width: 1400px) {
    margin-left: 250px;
    margin-right: 250px;
  }

  @media (max-width: 1100px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    font-size: 32px;
    text-align: left;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: ${primaryColors.gray};
  text-align: justify;
  padding-left: 440px;
  padding-right: 440px;

  @media (max-width: 1400px) {
    padding-left: 250px;
    padding-right: 250px;
  }

  @media (max-width: 1100px) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    text-align: left;
  }
`

const VideoHolder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

const PrizeHolder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 250px;

  @media (max-width: 768px) {
    padding: 20px;
    z-index: 4;
  }
`

const Prizes = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 180px;
  padding-top: 70px;
  padding-bottom: 50px;

  @media (max-width: 1247px) {
    gap: 120px;
  }

  @media (max-width: 1200px) {
    gap: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 20px;
    gap: 90px;
    z-index: 5;
  }
`

const PrizeSVG = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const PrizeImage = styled.img`
  height: auto;

  @media (max-width: 824px) {
    width: 120px;
  }

  @media (max-width: 768px) {
    width: 220px;
  }

  &:hover {
    cursor: pointer;
  }
`

const PrizeImageStartupSc = styled.img`
  height: auto;

  @media (max-width: 824px) {
    width: 120px;
  }

  @media (max-width: 824px) {
    width: 150px;
  }

  &:hover {
    cursor: pointer;
  }
`

const PrizeText = styled.h1`
  font-size: 20px;
  color: ${primaryColors.blue};

  &:hover {
    cursor: pointer;
  }
`

const SmallCubeController = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;

  @media (max-width: 970px) {
    margin-right: 0;
  }
`

const SmallerOutlinedOrangeCubeImage = styled.img`
  position: relative;
  width: 55px;
  height: 65px;
  top: -90px;
  right: 50px;

  @media (max-width: 970px) {
    top: 0;
    right: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const MediumOutlinedOrangeCubeImage = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
`

const RightHalfSmallCubeImage = styled.img`
  position: relative;
  width: 68px;
  height: 72px;

  @media (min-width: 768px) {
    display: none;
  }
`

const TitleContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
`
const GreenMarkerOutlinedImage = styled.img`
  position: relative;
  width: 70px;
  height: 82px;
  left: 40px;
  top: 200px;

  @media (max-width: 768px) {
    display: none;
  }
`

const IDHolder = styled.div``

interface PrizeProps {
  showVideoSection: boolean
}

function PrizesScreen({ showVideoSection }: PrizeProps) {
  const isMobile = useMedia('(max-width: 768px)')
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()
  const [prizeType, setPrizeType] = useState<string>()
  const [prizeImage, setPrizeImage] = useState<string>()
  const [alt, setAlt] = useState<string>()

  const handleModal = (typeOfPrize: string) => {
    if (modal === true) {
      setModal(false)
      setPrizeType('')
      setTitle('')
      setText('')
    }

    if (modal === false || prizeType !== typeOfPrize) {
      setPrizeType(typeOfPrize)
      setModal(true)
      switch (typeOfPrize) {
        case 'innovationPrize':
          setTitle('Prêmio Inovação Catarinense Professor Caspar Erich Stemmer')
          setText(stemmerPrize)
          setPrizeImage(PrizeStemmer)
          setAlt('Foto durante o Prêmio Inovação Catarinense Professor Caspar Erich Stemmer')
          break
        case 'mcticPrize':
          setTitle('IA² MCTI')
          setText(mctiPrize)
          setPrizeImage(PrizeIa2mcti)
          setAlt('Foto durante o Prêmio IA² MCTI do Ministério da Ciência, Tecnologia e Inovação')
          break
        case 'goodHabits':
          setTitle('Prêmio Boas Práticas')
          setText(boasPraticasPrize)
          setPrizeImage(PrizeBoasPraticas)
          setAlt('Foto durante o Prêmio Boas Práticas')
          break
        case 'startupSC':
          setTitle('Startup SC')
          setText(startupSCPrize)
          setPrizeImage(PrizeStartupSC)
          setAlt('Foto durante o Prêmio Startup SC')
      }
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setTitle('')
    setText('')
  }

  return (
    <Section>
      {showVideoSection ? (
        <>
          <Holder>
            <Title>Reconhecimento conquistado com dedicação e esforço</Title>
            <Text>
              Os prêmios podem contribuir para o reconhecimento púbico a respeito da qualidade dos
              produtos desenvolvidos, da credibilidade da empresa, bem como do seu compromisso com
              os stakeholders. A participação, com vitória ou não, traz não só visibilidade e
              prestígio, mas também reconhecimento do mercado e, muitas vezes, lições que, se
              compreendidas, podem tornar a empresa ainda mais competitiva.
            </Text>
          </Holder>
          <MediumOutlinedOrangeCubeImage
            src={MediumOutlinedOrangeCube}
            alt='Cubo laranja grande sem preenchimento'
          />
          <VideoHolder>
            <YouTube
              videoId='6eHMoqUrMe4'
              opts={{
                width: '700px',
                height: '380px',
              }}
            />
          </VideoHolder>
          <SmallCubeController>
            <SmallerOutlinedOrangeCubeImage
              src={SmallerOutlinedOrangeCube}
              alt='Cubo laranja grande sem preenchimento'
            />
          </SmallCubeController>
          <GreenMarkerOutlinedImage
            src={GreenMarkerOutlined}
            alt='Cubo laranja pequeno sem preenchimento ao lado do título'
          />
        </>
      ) : null}
      {isMobile ? (
        <TitleContainer>
          <PrizeTitle>Nossos prêmios recebidos</PrizeTitle>
          <RightHalfSmallCubeImage
            src={RightHalfSmallCube}
            alt='Cubo laranja pequeno sem preenchimento ao lado do título'
          />
        </TitleContainer>
      ) : null}
      <PrizeHolder id={'receivedPrizes'}>
        <IDHolder id={'StemmerPrize'} />
        <IDHolder id={'MCTIPrize'} />
        <IDHolder id={'BoasPraticasPrize'} />
        <IDHolder id={'StartupSCPrize'} />
        {isMobile ? null : <PrizeTitle>Prêmios Recebidos</PrizeTitle>}
        <Prizes>
          <PrizeImage
            onClick={() => handleModal('innovationPrize')}
            src={InnovationPrizeCatarinense}
            alt='Logo Prêmio Inovação Catarinense Professor Caspar Erich Stemmer'
            title='Prêmio Inovação Catarinense Professor Caspar Erich Stemmer'
          />
          {isMobile && modal && prizeType === 'innovationPrize' ? (
            <Modal
              image={prizeImage}
              alt={alt}
              text={text}
              title={title}
              handleClose={handleCloseModal}
            />
          ) : null}
          <PrizeSVG
            title='Prêmio IA² MCTI do Ministério da Ciência, Tecnologia e Inovação'
            onClick={() => handleModal('mcticPrize')}
          >
            <McticPrize width={173} height={50} />
          </PrizeSVG>
          {isMobile && modal && prizeType === 'mcticPrize' ? (
            <Modal
              image={prizeImage}
              alt={alt}
              text={text}
              title={title}
              handleClose={handleCloseModal}
            />
          ) : null}
          <PrizeText title='Prêmio Boas Praticas' onClick={() => handleModal('goodHabits')}>
            Prêmio Boas Praticas
          </PrizeText>
          {isMobile && modal && prizeType === 'goodHabits' ? (
            <Modal
              image={prizeImage}
              alt={alt}
              text={text}
              title={title}
              handleClose={handleCloseModal}
            />
          ) : null}
          {isMobile ? (
            <PrizeImageStartupSc
              onClick={() => handleModal('startupSC')}
              src={StartupSCLogoImage}
              alt='Logo Prêmio StartupSC'
              title='Prêmio StartupSC'
            />
          ) : (
            <PrizeSVG title='StartupSC' onClick={() => handleModal('startupSC')}>
              <StartupSCLogo width={128} height={128} />
            </PrizeSVG>
          )}
          {isMobile && modal && prizeType === 'startupSC' ? (
            <Modal
              image={prizeImage}
              alt={alt}
              text={text}
              title={title}
              handleClose={handleCloseModal}
            />
          ) : null}
        </Prizes>
        {modal && !isMobile ? (
          <>
            <Modal
              image={prizeImage}
              alt={alt}
              text={text}
              title={title}
              handleClose={handleCloseModal}
            />
          </>
        ) : null}
      </PrizeHolder>
    </Section>
  )
}

export default PrizesScreen
