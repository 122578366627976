import React from 'react'
import StartScreen from './startScreen'
import CompanyScreen from './companyScreen'
import WhiteLabelScreen from './whiteLabelScreen'
import PrizesScreen from './prizesScreen'
import Footer from '../reusable/footer'
import NavBar from '../reusable/navbar'
import VideoHalfScreen from './videoHalfScreen'
import BlankSpace from './blankSpace'
import ScrollToTop from '../reusable/scrollToTop'

function HomeScreen() {
  return (
    <>
      <ScrollToTop />
      <StartScreen />
      <CompanyScreen />
      <WhiteLabelScreen />
      <BlankSpace />
      <VideoHalfScreen />
      <PrizesScreen showVideoSection />
      <Footer />
      <NavBar changeBackground={false} />
    </>
  )
}

export default HomeScreen
