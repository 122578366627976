import React from 'react'
import styled from 'styled-components'
import Interest from './openPositionsComponents/interest'
import VacantJob from './openPositionsComponents/vacantJob'

const Section = styled.section`
  flex: 1 0 50%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  background: #fff;
  margin-top: 173px;
  margin-bottom: 140px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

function OpenPositions() {
  return (
    <Section>
      <VacantJob />
      <Interest />
    </Section>
  )
}

export default OpenPositions
