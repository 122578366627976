import React from 'react'
import { hoverColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import halfScreenImage from './../../images/halfScreenImage.png'
import halfScreenImageMobile from './../../images/halfScreenImageMobile.png'
import { containerCSS } from 'src/design/design'
// import { LargeOutlinedButton } from '../reusable/buttons'
import { useMedia } from 'react-use'

const MobileSection = styled.section`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: ${hoverColors.blue};
`

const MobileBoxHolder = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`

const Holder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: -300px;
`

const ImageDesktop = styled.img`
  width: 100%;
  height: auto;
  object-fit: none;
`

const BlueTitle = styled.h1`
  font-size: 1.3em;
  font-weight: 600;
  line-height: 22px;
  color: ${primaryColors.blue};
`

const MobileText = styled.p`
  color: #fff;
  padding-right: 30px;
  padding-left: 30px;
`

const NeuronWord = styled.span`
  font-weight: bold;
  font-style: italic;
`

// const MobileButtonHolder = styled.div`
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   margin-top: 80px;
//   margin-left: 40px;
//   margin-right: 40px;
// `

const negativeMargin = 270

const Section = styled.section`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 50px;
  height: auto;
  background-color: ${hoverColors.blue};
  padding-bottom: 150px;
`

const Container = styled.div`
  ${containerCSS};
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: -440px;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${-negativeMargin}px;
`

const TopDescription = styled.div`
  display: flex;
  flex-direction: column;

  height: ${negativeMargin}px;
  padding: 40px 10%;

  @media (max-width: 940px) {
    padding: 10px 10%;
  }
`
const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10%;
  margin-top: 55px;
`
const Text = styled.span`
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  max-width: 400px;
  margin-bottom: 40px;
`

const OrangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 315px;
    height: 35px;
    font-size: 15px;
  }
`

const Link = styled.a`
  text-decoration: none;
`

function ImageHalfScreen() {
  const isMobile = useMedia('(max-width: 768px)')

  return isMobile ? (
    <MobileSection>
      <Holder>
        <ImageHolder>
          <Image
            src={halfScreenImageMobile}
            alt={
              'Imagem de mockup com um print do Prix Neuron e uma mulher utilizando um smartphone'
            }
          />
        </ImageHolder>
        <MobileBoxHolder>
          <MobileText>
            Nossa tecnologia <NeuronWord>NEURON®</NeuronWord> compila a massividade de dados do seu
            nicho de negócio em todas as suas esferas para entregar informações em tempo real. As
            informações são de tal forma estratégicas que seu negócio pode predizer recorrências e
            examinar padrões.
          </MobileText>
          <MobileText style={{ paddingTop: '30px' }}>
            Fornecendo mapas, gráficos e informações baseadas em inteligência artificial e
            georreferenciamento a plataforma fornece dados para viabilizar a melhor condução do seu
            negócio bem como embasamento para a tomada de decisões.
          </MobileText>
          <MobileText style={{ paddingTop: '30px' }}>
            As informações são entregues em um formato muito visual, garantindo a agilidade na
            organização de informação para relatórios, bem como mostrando mapas e focos de
            ocorrências sazonais.
          </MobileText>
          {/* <MobileButtonHolder>
            <LargeOutlinedButton>Conheça o Prix Neuron</LargeOutlinedButton>
          </MobileButtonHolder> */}
        </MobileBoxHolder>
      </Holder>
    </MobileSection>
  ) : (
    <Section>
      <Container>
        <ImageContainer>
          <ImageDesktop
            src={halfScreenImage}
            alt='Imagem de mockup com um print do Prix Neuron e uma mulher utilizando um smartphone'
          />
        </ImageContainer>
        <DescriptionContainer>
          <TopDescription>
            <BlueTitle>
              Nossa tecnologia <NeuronWord>NEURON®</NeuronWord> compila a massividade de dados do
              seu nicho de negócio em todas as suas esferas para entregar informações em tempo real.
              As informações são de tal forma estratégicas que seu negócio pode predizer
              recorrências e examinar padrões.
            </BlueTitle>
          </TopDescription>
          <BottomDescription>
            <Text>
              Fornecendo mapas, gráficos e informações baseadas em inteligência artificial e
              georreferenciamento a plataforma fornece dados para viabilizar a melhor condução do
              seu negócio bem como embasamento para a tomada de decisões.
            </Text>
            <Text>
              As informações são entregues em um formato muito visual, garantindo a agilidade na
              organização de informação para relatórios, bem como mostrando mapas e focos de
              ocorrências sazonais.
            </Text>
            <Link href='#Contact'>
              <OrangeButton title={'Converse com o nosso Consultor'}>
                Converse com o nosso Consultor
              </OrangeButton>
            </Link>
          </BottomDescription>
        </DescriptionContainer>
      </Container>
    </Section>
  )
}

export default ImageHalfScreen
