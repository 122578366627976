import styled from 'styled-components'

export const VideoMobileOutlinedGreenCubeImage = styled.img`
  position: relative;
  width: 80px;
  height: 70px;
  top: -30px;
  left: 170px;
  z-index: 3;

  @media (min-width: 768px) {
    display: none;
  }
`

export const VideoMobileFullOrangeCubeImage = styled.img`
  position: relative;
  width: 50px;
  height: 60px;
  top: -40px;
  left: -200px;
  z-index: 1;

  @media (min-width: 768px) {
    display: none;
  }
`

export const VideoMobileFullMediumOrangeOutlinedImage = styled.img`
  position: relative;
  width: 65px;
  height: 64px;
  top: 30px;
  left: -147px;

  @media (min-width: 768px) {
    display: none;
  }
`

export const VideoMobileOutlinedSmallOrangeCubeImage = styled.img`
  position: relative;
  width: 30px;
  height: 25px;
  top: 30px;
  left: 140px;

  @media (min-width: 768px) {
    display: none;
  }
`
