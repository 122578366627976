import React, { lazy } from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import { selectedColors, containerCSS } from 'src/design/design'
import { LargeOutlinedButton, MediumOutlinedButton } from '../reusable/buttons'
import { useMedia } from 'react-use'
import GreenCube from './../../images/cubesAndMarkers/green_cube.png'
import OrangeOutlinedSmaller from './../../images/cubesAndMarkers/orange_outlined_smaller_cube.png'
import BigOutlinedOrangeCube from './../../images/cubesAndMarkers/big_outlined_orange_cube.png'
import { Link } from 'react-router-dom'

const LazyVideo = lazy(() => import('./components/videoHalfScreenLazy'))

const MobileSection = styled.section`
  flex: 1;
  display: flex;
  height: 110vh;
  background: linear-gradient(180deg, ${primaryColors.blue} 17%, ${selectedColors.gray} 10%);

  @media (max-width: 375px) {
    height: 150vh;
  }
`

const MobileBoxHolder = styled.div`
  margin-top: 50px;
`

const MobileBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-left: 30px;
  margin-right: 30px;
`

const MobileOrangeTitle = styled.h2`
  font-size: 32px;
  color: ${primaryColors.orange};
`

const MobileBlueTitle = styled.h2`
  font-size: 32px;
  color: ${primaryColors.blue};
`

const Holder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const VideoHolder = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    z-index: 4;
  }
`

const OrangeTitle = styled.h2`
  font-size: 44px;
  color: ${primaryColors.orange};

  @media (max-width: 1100px) {
    font-size: 1.5em;
  }

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const BlueTitle = styled.h2`
  font-size: 44px;
  color: ${primaryColors.blue};

  @media (max-width: 1100px) {
    font-size: 1.5em;
  }

  @media (max-width: 768px) {
    font-size: 32px;
  }
`

const MobileText = styled.p`
  color: ${primaryColors.gray};
  padding-right: 30px;
  padding-left: 30px;
`

const MobileButtonHolder = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-left: 40px;
  margin-right: 40px;
`

const negativeMargin = 230

const Section = styled.section`
  flex: 1;
  display: flex;
  box-shadow: 0 -12px 20px 0 rgba(0, 0, 0, 0.15);
  justify-content: center;
  padding: 0 50px;
`

const Container = styled.div`
  ${containerCSS};
  padding-bottom: 250px;

  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
`

const VideoContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 300px;
  max-width: 500px;
  margin-top: ${-negativeMargin}px;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${-negativeMargin}px;
`

const TopDescription = styled.div`
  display: flex;
  flex-direction: column;
  background: ${selectedColors.gray};
  height: ${negativeMargin}px;
  padding: 30px 10%;
  box-shadow: inset 0 -12px 20px 0 rgba(0, 0, 0, 0.15);
`

const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10%;
`

const Text = styled.span`
  font-size: 18px;
  max-width: 400px;
  margin-bottom: 40px;
`

const GreenCubeImage = styled.img`
  position: relative;
  width: 60px;
  height: 67px;

  top: 260px;
  right: 30px;
  z-index: 4;

  @media (max-width: 768px) {
    top: -30px;
    left: 330px;
  }

  @media (max-width: 667px) {
    top: -30px;
    left: 310px;
  }
`

const OrangeOutlinedSmallerImage = styled.img`
  position: relative;
  display: flex;
  align-self: flex-end;
  width: 94px;
  height: 102px;
  z-index: 1;
  top: -50px;
  left: 30px;

  @media (max-width: 900px) {
    left: 10px;
  }

  @media (max-width: 768px) {
    display: flex;
    top: 10px;
    left: 150px;
  }
`

const BigOutlinedOrangeCubeImage = styled.img`
  position: relative;
  display: flex;
  align-self: flex-start;
  width: 150px;
  height: 178px;
  top: -160px;
  left: -630px;
  z-index: 1;
`

const Links = styled(Link)`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`

function VideoHalfScreen() {
  const isMobile = useMedia('(max-width: 768px)')

  return isMobile ? (
    <MobileSection>
      <Holder>
        <VideoHolder>
          <LazyVideo width='320' height='240' />
        </VideoHolder>
        <GreenCubeImage src={GreenCube} alt='Cubo verde' />
        <MobileBoxHolder>
          <MobileBox>
            <MobileOrangeTitle>Prix Saúde</MobileOrangeTitle>
            <MobileBlueTitle>Gestão e inteligência de dados para saúde públicas</MobileBlueTitle>
          </MobileBox>
          <MobileText>
            O Prix Saúde é a solução em saúde pública da Prix. Ela auxilia o poder público que
            precisa de informações em tempo real sobre a escala no monitoramento e enfrentamento da
            pandemia, obtendo mapas, gráficos e informações baseadas em IA e georreferenciamento,
            garantindo a melhor condução do vírus.
          </MobileText>
          <Links to={'/neuron'} title='Conheça nossos produtos'>
            <MobileButtonHolder>
              <LargeOutlinedButton>Conheça o Prix Saúde</LargeOutlinedButton>
            </MobileButtonHolder>
          </Links>
        </MobileBoxHolder>
      </Holder>
    </MobileSection>
  ) : (
    <Section>
      <Container>
        <VideoContainer>
          <LazyVideo width='500' height='300' />
          <GreenCubeImage src={GreenCube} alt='Cubo verde' />
          <BigOutlinedOrangeCubeImage
            src={BigOutlinedOrangeCube}
            alt='Cubo laranja sem preenchimento'
          />
        </VideoContainer>
        <DescriptionContainer>
          <TopDescription>
            <OrangeTitle>Prix Saúde</OrangeTitle>
            <BlueTitle>Gestão e inteligência de dados para saúde pública</BlueTitle>
          </TopDescription>
          <OrangeOutlinedSmallerImage
            src={OrangeOutlinedSmaller}
            alt='Pequeno cubo laranja sem preenchimento'
          />
          <BottomDescription>
            <Text>
              O Prix Saúde é a solução em saúde pública da Prix. Ela auxilia o poder público que
              precisa de informações em tempo real sobre a escala no monitoramento e enfrentamento
              da pandemia, obtendo mapas, gráficos e informações baseadas em IA e
              georreferenciamento, garantindo a melhor condução do distanciamento social e
              rastreamento do vírus.
            </Text>
            <Links to={'/neuron'} title='Conheça nossos produtos'>
              <MediumOutlinedButton>Conheça o Prix Saúde</MediumOutlinedButton>
            </Links>
          </BottomDescription>
        </DescriptionContainer>
      </Container>
    </Section>
  )
}

export default VideoHalfScreen
