import React, { useEffect } from 'react'
import NavBar from '../reusable/navbar'
import Footer from '../reusable/footer'
import Cover from '../reusable/cover'
import ModuleArtificialIntelligence from './moduleArtificialIntelligence'
import OurSolutions from './ourSolutions'
import WhiteLabel from './whiteLabel'
import ImageHalfScreen from './halfImage'
import BlankSpace from './blankSpace'
import ScrollToTop from '../reusable/scrollToTop'
import { useParams } from 'react-router'
import { scroller } from 'react-scroll'

function HomeArtificialIntelligence() {
  const { sectionId } = useParams()

  useEffect(() => {
    if (sectionId) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: true,
      })
    }
  }, [sectionId])

  return (
    <>
      <ScrollToTop />
      <Cover title={'Soluções em negócios'} />
      <ModuleArtificialIntelligence />
      <OurSolutions />
      <BlankSpace />
      <ImageHalfScreen />
      <WhiteLabel />
      <Footer darkBlue />
      <NavBar changeBackground />
    </>
  )
}

export default HomeArtificialIntelligence
