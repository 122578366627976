import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import Office from './../../images/office.png'
import { useMedia } from 'react-use'
import { ReactComponent as DoubleQuotes } from './../../images/double-quotes.svg'
import OutlinedAngleOrangeCube from './../../images/cubesAndMarkers/outlined_angle_orange_cube.png'
import OutlinedCubeHalfOrange from './../../images/cubesAndMarkers/outlined_cube_half_orange.png'

const Section = styled.section`
  flex: 1;
  display: flex;
  background-image: url(${Office});
  background-size: cover;
  background-color: ${primaryColors.blue};
  height: 840px;
  flex-direction: column;
`

const MobileSection = styled.section`
  flex: 1;
  display: flex;
  background-color: ${primaryColors.blue};
  height: 800px;
  flex-direction: column;
  align-self: center;
`

const OrangeText = styled.span`
  font-weight: 400;
  font-size: 27px;
  line-height: 40px;
  color: ${primaryColors.orange};

  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 60px;
    margin-left: 70px;
    margin-right: 70px;
  }
`

const WhiteText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-left: 70px;
    margin-right: 70px;
  }
`

const DoubleQuotesContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 65px;
  margin-left: 150px;
`

const DoubleQuotesContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Holder = styled.div`
  flex: 1 0 50%;
  display: flex;
  flex-direction: row;
  gap: 190px;
  margin-bottom: 100px;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 50px;

  @media (max-width: 980px) {
    margin-left: 100px;
    margin-right: 100px;
  }
`

const MobileImageHolder = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 40px;
`

const CubeImage = styled.img`
  display: flex;
  align-self: flex-end;
  width: 168px;
  height: 170px;
  margin-bottom: -80px;

  @media (max-width: 980px) {
    width: 137px;
    height: 136px;
  }
`

function QuoteScreen() {
  const isMobile = useMedia('(max-width: 768px)')

  return isMobile ? (
    <MobileSection id={'believe'}>
      <DoubleQuotesContainerMobile>
        <DoubleQuotes width={80} height={70} />
      </DoubleQuotesContainerMobile>
      <OrangeText>
        Acreditamos que as constantes transformações do mercado nos permitem evoluir e por isso nos
        dedicamos de forma integral na busca de novas soluções e alternativas.
      </OrangeText>
      <WhiteText>
        A Prix Tech entrega muito mais do que análise de dados para inteligência e gestão.
        Acreditamos em viabilizar às equipes dados concretos para que possam embasar seus trabalhos
        e análises, permitindo a eles fazerem descobertas significativas nas suas áreas de atuação e
        impulsionar mudanças reais em seus campos de atuação. Em outras palavras, estamos
        preocupados com o legado.
      </WhiteText>
      <MobileImageHolder>
        <CubeImage src={OutlinedCubeHalfOrange} alt='Cubo laranja sem preenchimentos' />
      </MobileImageHolder>
    </MobileSection>
  ) : (
    <Section id={'believe'}>
      <DoubleQuotesContainer>
        <DoubleQuotes width={121} height={95} />
      </DoubleQuotesContainer>
      <Container>
        <Holder>
          <OrangeText>
            Acreditamos que as constantes transformações do mercado nos permitem evoluir e por isso
            nos dedicamos de forma integral na busca de novas soluções e alternativas.
          </OrangeText>
          <WhiteText>
            A Prix Tech entrega muito mais do que análise de dados para inteligência e gestão.
            Acreditamos em viabilizar às equipes dados concretos para que possam embasar seus
            trabalhos e análises, permitindo a eles fazerem descobertas significativas nas suas
            áreas de atuação e impulsionar mudanças reais em seus campos de atuação. Em outras
            palavras, estamos preocupados com o legado.
          </WhiteText>
        </Holder>
      </Container>
      <CubeImage src={OutlinedAngleOrangeCube} alt='Cubo laranja sem preenchimentos' />
    </Section>
  )
}

export default QuoteScreen
