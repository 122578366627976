import React from 'react'
import { useMedia } from 'react-use'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import MobileElements from './publicHealthComponents/mobileElements'
import DesktopElements from './publicHealthComponents/desktopElements'

const Section = styled.section`
  background-color: #fff;
  height: auto;
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: ${primaryColors.blue};
  line-height: 44px;
  text-align: center;
  margin-top: 185px;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-top: 120px;
    margin-bottom: 60px;
    font-size: 35px;
    line-height: 34px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: left;
  }
`

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  gap: 38px;
  margin-left: 350px;
  margin-right: 350px;

  @media (max-width: 1650px) {
    margin-left: 300px;
    margin-right: 300px;
  }

  @media (max-width: 1450px) {
    margin-left: 350px;
    margin-right: 350px;
  }

  @media (max-width: 1350px) {
    margin-left: 200px;
    margin-right: 200px;
  }

  @media (max-width: 1250px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 1150px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
    margin-bottom: 0px;
    margin-left: 50px;
    margin-right: 50px;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${primaryColors.gray};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

function PublicHealth() {
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <Section id={'publicManagement'}>
      <Title>Nossas soluções em saúde</Title>
      <TextHolder>
        <Text>
          A plataforma tem o objetivo de gerar a máxima transparência por meio de gestão e
          inteligência de dados. A plataforma é focada na gestão de dados e aqui é aplicada à saúde.
          Com a plataforma é possível realizar análises temporais, sazonalidade de ocorrências e
          ainda é possível buscar padrões e recorrências.
        </Text>
      </TextHolder>
      {isMobile ? <MobileElements /> : <DesktopElements />}
    </Section>
  )
}

export default PublicHealth
