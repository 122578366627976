import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
  height: 500px;
  background-color: #fff;

  @media (max-width: 768px) {
    height: 300px;
  }
`

function BlankSpace() {
  return <Section />
}

export default BlankSpace
