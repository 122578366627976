export const firstText = `
  As informações são entregues em um formato muito visual, garantindo a agilidade na
  organização de informações para relatórios, bem como mostrando mapa de surtos e focos de
  doenças específicas.
`

export const secondText = `
  A plataforma compila a massividade de dados da saúde em todas as suas esferas para
  entregar informações em tempo real. As informações são de tal forma estratégicas que a
  secretaria pode, por exemplo, controlar surtos de sintomas e algumas patologias no dia
  zero.
`

export const thirdText = `
  Dessa forma oferece oportunidades para que sejam realizadas atividades de prevenção de
  doenças e promoção da saúde em domicílios ou junto a coletividades, em conformidade com
  os princípios e diretrizes do SUS e da Organização Mundial da Saúde (OMS).
`
