import React from 'react'
import ScrollToTop from './scrollToTop'
import Footer from './footer'
import NavBar from './navbar'
import styled from 'styled-components'
import { ReactComponent as PrixelNotFound } from './../../images/prixel_not_found.svg'
import { primaryColors } from 'src/design/design'
import { useMedia } from 'react-use'

const Section = styled.section`
  display: flex;
  height: 100vh;
  background-color: #fff;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 44px;
  color: #000;
  line-height: 44px;
  text-align: center;
  margin-top: 30px;

  @media (max-width: 768px) {
    font-size: 38px;
  }
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 315px;
    height: 35px;
    font-size: 15px;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

function NotFound() {
  const isMobile = useMedia('(max-width: 768px)')

  return (
    <>
      <ScrollToTop />
      <Section>
        <Container>
          <PrixelNotFound width={isMobile ? 200 : 500} height={isMobile ? 200 : 400} />
          <Title>404 - Página não encontrada</Title>
          <LinkAct title='Voltar a página principal' href='/'>
            <Button>Página inicial</Button>
          </LinkAct>
        </Container>
      </Section>
      <Footer />
      <NavBar changeBackground={false} />
    </>
  )
}

export default NotFound
