export const dataAnalysis = `
  Nós trabalhamos com Análise de Dados, utilizando técnicas e ferramentas para extrair informações
  relevantes e insights valiosos a partir de grandes volumes de dados. Com uma abordagem estratégica e metodológica,
  ajudamos nossos clientes a tomar decisões embasadas em dados reais, contribuindo para o sucesso de seus negócios.
`

export const temporalAnalysis = `
  Trabalhamos com Análise Temporal, utilizando técnicas e ferramentas para identificar padrões e tendências
  em dados históricos. Com uma abordagem sistemática e rigorosa, ajudamos nossos clientes a entender 
  o comportamento de seus negócios ao longo do tempo, identificando oportunidades de melhoria e antecipando possíveis riscos.
  Com a análise temporal, nossos clientes podem tomar decisões mais informadas e estratégicas,
  garantindo o sucesso de seus negócios a longo prazo.
`

export const disruptiveSolutions = `
  Trabalhar com  Soluções Disruptivas, nos permite desenvolver e implementar tecnologias inovadoras que transformam
  a forma como as empresas operam e se relacionam com seus stakeholders. Com uma equipe altamente qualificada e experiente,
  estamos sempre em busca de novas ideias e tendências para oferecer soluções personalizadas e eficientes,
  que agregam valor aos negócios de nossos clientes e os diferenciam no mercado.
`

export const georeferencing = `
  Trabalhamos com mapas de alta qualidade e resolução para garantir o cadastro correto da geolocalização de cada
  elemento a ser analisado dentro do padrão estrutural. Com essa informação é possível fornecer dados detalhados,
  em mapas de calor ou georreferenciados para o seu negócio.
`

export const artificialIntelligence = `
  A plataforma white label possui inteligência artificial própria, a qual denominamos Ella,
  que quando aplicada a big data e cloud computing, geram uma estrutura informacional de extrema
  relevância para que os gestores tenham em suas mãos a visão necessária para dar a devida atenção ao nicho do seu negócio.
  Possuímos depósito de patente desta inteligência artificial.
  `

export const strategicManagement = `
  A plataforma white label traz para a palma da mão os principais dados sobre o recorte escolhido,
  oportunizando às equipes de trabalho informações para o processo de construção dos programas
  mais adequados às demandas do seu negócio. 
`
