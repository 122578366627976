import React, { useState } from 'react'
import SmallModal from 'src/components/reusable/smallModal'
import { complementaryColors, primaryColors } from 'src/design/design'
import styled from 'styled-components'
import { ReactComponent as StrategicManagementGray } from './../../../images/whiteLabelIcons/gray/strategic-management.svg'
import { ReactComponent as StrategicManagementOrange } from './../../../images/whiteLabelIcons/orange/strategic-management.svg'
import { ReactComponent as GeoreferencingGray } from './../../../images/whiteLabelIcons/gray/georeferencing.svg'
import { ReactComponent as GeoreferencingOrange } from './../../../images/whiteLabelIcons/orange/georeferencing.svg'
import { ReactComponent as DisruptiveSolutionsGray } from './../../../images/whiteLabelIcons/gray/disruptive-solutions.svg'
import { ReactComponent as DisruptiveSolutionsOrange } from './../../../images/whiteLabelIcons/orange/disruptive-solutions.svg'
import { ReactComponent as ArtificialIntelligenceGray } from './../../../images/whiteLabelIcons/gray/artificial-intelligence.svg'
import { ReactComponent as ArtificialIntelligenceOrange } from './../../../images/whiteLabelIcons/orange/artificial-intelligence.svg'
import { ReactComponent as TemporalAnalysisGray } from './../../../images/whiteLabelIcons/gray/temporal-analysis.svg'
import { ReactComponent as TemporalAnalysisOrange } from './../../../images/whiteLabelIcons/orange/temporal-analysis.svg'
import {
  artificialIntelligence,
  citizenApp,
  georeferencing,
  healthConsultancy,
  publicManagement,
} from 'src/components/reusable/publicHealthText'

const Element = styled.div`
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${complementaryColors.rajahOrange};
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`

const ElementText = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${primaryColors.blue};

  @media (max-width: 768px) {
    color: #fff;
  }
`

const Elements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;

  @media (max-width: 960px) {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    margin: 24px;
    gap: 13px 16px;
  }
`

function DesktopElements() {
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()
  const [iconColorStatus, setIconColorStatus] = useState<string>()
  const [solutionType, setSolutionType] = useState<string>()

  const handleModal = (typeOfSolution: string) => {
    if (modal === true) {
      setModal(false)
      setTitle('')
      setText('')
      setIconColorStatus('')
    }

    if (modal === false || solutionType !== typeOfSolution) {
      setSolutionType(typeOfSolution)
      setModal(true)
      switch (typeOfSolution) {
        case 'publicManagement':
          setTitle('Gestão Pública')
          setText(publicManagement)
          setIconColorStatus('publicManagement')
          break
        case 'georeferencing':
          setTitle('Georreferenciamento')
          setText(georeferencing)
          setIconColorStatus('georeferencing')
          break
        case 'citizenApp':
          setTitle('App para o Cidadão')
          setText(citizenApp)
          setIconColorStatus('citizenApp')
          break
        case 'artificialIntelligence':
          setTitle('Inteligência Artificial')
          setText(artificialIntelligence)
          setIconColorStatus('artificialIntelligence')
          break
        case 'healthConsultancy':
          setTitle('Consultoria de saúde e E-SUS')
          setText(healthConsultancy)
          setIconColorStatus('healthConsultancy')
          break
      }
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setTitle('')
    setText('')
    setIconColorStatus('')
  }

  return (
    <>
      <Elements>
        <Element title={'Georreferenciamento'} onClick={() => handleModal('georeferencing')}>
          {iconColorStatus === 'georeferencing' ? (
            <GeoreferencingOrange width={64} height={64} />
          ) : (
            <GeoreferencingGray width={64} height={64} />
          )}
          <ElementText>Georreferenciamento</ElementText>
        </Element>
        <Element title={'Gestão Pública'} onClick={() => handleModal('publicManagement')}>
          {iconColorStatus === 'publicManagement' ? (
            <StrategicManagementOrange width={64} height={64} />
          ) : (
            <StrategicManagementGray width={64} height={64} />
          )}
          <ElementText>Gestão Pública</ElementText>
        </Element>

        <Element title={'App para o Cidadão'} onClick={() => handleModal('citizenApp')}>
          {iconColorStatus === 'citizenApp' ? (
            <DisruptiveSolutionsOrange width={64} height={64} />
          ) : (
            <DisruptiveSolutionsGray width={64} height={64} />
          )}
          <ElementText>App para o Cidadão</ElementText>
        </Element>
        <Element
          title={'Inteligência Artificial'}
          onClick={() => handleModal('artificialIntelligence')}
        >
          {iconColorStatus === 'artificialIntelligence' ? (
            <ArtificialIntelligenceOrange width={64} height={64} />
          ) : (
            <ArtificialIntelligenceGray width={64} height={64} />
          )}

          <ElementText>Inteligência Artificial</ElementText>
        </Element>
        <Element
          title={'Consultoria em Saúde e E-SUS'}
          onClick={() => handleModal('healthConsultancy')}
        >
          {iconColorStatus === 'healthConsultancy' ? (
            <TemporalAnalysisOrange width={64} height={64} />
          ) : (
            <TemporalAnalysisGray width={64} height={64} />
          )}
          <ElementText>Consultoria em Saúde e E-SUS</ElementText>
        </Element>
      </Elements>
      {modal ? <SmallModal text={text} title={title} handleClose={handleCloseModal} /> : null}
    </>
  )
}

export default DesktopElements
