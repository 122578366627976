import React, { useState } from 'react'
import NeuronApp from './../../../images/neuron-app.png'
import { OrangeButton } from '../../reusable/mocker'
import styled from 'styled-components'
import { BallCSS, primaryColors } from 'src/design/design'
import SmallModal from 'src/components/reusable/smallModal'
import { firstText, secondText, thirdText } from 'src/components/reusable/agilityText'

const Section = styled.section`
  background-color: #fff;
  display: flex;
  height: auto;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  gap: 10px;

  @media (max-width: 1600px) {
    margin-left: 240px;
    margin-right: 240px;
  }

  @media (max-width: 1400px) {
    margin-left: 140px;
    margin-right: 140px;
  }

  @media (max-width: 1200px) {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

const Holder = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  color: ${primaryColors.blue};
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
`

const ImageHolder = styled.div`
  padding-top: 40px;
`

const Image = styled.img`
  height: auto;
  width: 100%;
  object-fit: fill;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
`

const BallContainer = styled.div``

const FirstBall = styled.div`
  ${BallCSS};
  top: 310px;
  left: 50px;
`

const SecondBall = styled.div`
  ${BallCSS};
  top: 390px;
  left: 80px;
`

const ThirdBall = styled.div`
  ${BallCSS};
  top: 385px;
  left: 220px;
`

const Link = styled.a`
  text-decoration: none;
`

function AgilityMobileElements() {
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState<string>()
  const [text, setText] = useState<string>()
  const [solutionType, setSolutionType] = useState<string>()

  const handleModal = (typeOfSolution: string) => {
    if (modal === true) {
      setModal(false)
      setTitle('')
      setText('')
    }

    if (modal === false || solutionType !== typeOfSolution) {
      setModal(true)
      setSolutionType(typeOfSolution)
      switch (typeOfSolution) {
        case 'firstText':
          setTitle('')
          setText(firstText)
          break
        case 'secondText':
          setTitle('')
          setText(secondText)
          break
        case 'thirdText':
          setTitle('')
          setText(thirdText)
          break
      }
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setTitle('')
    setText('')
  }

  return (
    <Section>
      <Container>
        <BallContainer>
          <FirstBall onClick={() => handleModal('firstText')} />
          <SecondBall onClick={() => handleModal('secondText')} />
          <ThirdBall onClick={() => handleModal('thirdText')} />
        </BallContainer>
        <Holder>
          <Title>Agilidade e simplicidade na</Title>
          <Title>Gestão de Saúde pública</Title>
        </Holder>
        <ImageHolder>
          <Image
            src={NeuronApp}
            alt='Imagem de um mockup em um smartphone demonstrando o funcionamento do aplicativo Cidadão do Prix Neuron'
          />
        </ImageHolder>
        {modal ? <SmallModal text={text} title={title} handleClose={handleCloseModal} /> : null}
      </Container>
      <ButtonContainer>
        <Link href='#Contact' title='Converse com nosso consultor'>
          <OrangeButton>Converse com nosso consultor</OrangeButton>
        </Link>
      </ButtonContainer>
    </Section>
  )
}

export default AgilityMobileElements
