import React from 'react'
import NeuronApp from './../../../images/neuron-app.png'
import { OrangeButton, OutlinedButton } from '../../reusable/mocker'
import styled from 'styled-components'
import { primaryColors } from 'src/design/design'
import { Link } from 'react-router-dom'

const Section = styled.section`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-left: 300px;
  margin-right: 300px;
  gap: 10px;

  @media (max-width: 1700px) {
    margin-left: 240px;
    margin-right: 240px;
  }

  @media (max-width: 1600px) {
    margin-left: 200px;
    margin-right: 200px;
  }

  @media (max-width: 1500px) {
    margin-left: 110px;
    margin-right: 110px;
  }

  @media (max-width: 1400px) {
    margin-left: 110px;
    margin-right: 110px;
  }

  @media (max-width: 1300px) {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`

const Title = styled.h2`
  color: ${primaryColors.blue};
  font-size: 44px;
  font-weight: 700;
  line-height: 48px;
`

const Text = styled.span`
  color: ${primaryColors.gray};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: scale-down;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
  margin-right: 80px;
  margin-bottom: 100px;
`

const Links = styled(Link)`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

function AgilityDesktopElements() {
  return (
    <Section id={'agility'}>
      <Container>
        <Holder>
          <Title>Agilidade e simplicidade na Gestão de Saúde pública</Title>
          <Text>
            As informações são entregues em um formato muito visual, garantindo a agilidade na
            organização de informações para relatórios, bem como mostrando mapa de surtos e focos de
            doenças específicas.
          </Text>
        </Holder>
        <Image
          src={NeuronApp}
          alt='Imagem de um mockup em um smartphone demonstrando o funcionamento do aplicativo Cidadão do Prix Neuron'
        />
        <Holder>
          <Text>
            A plataforma compila a massividade de dados da saúde em todas as suas esferas para
            entregar informações em tempo real. As informações são de tal forma estratégicas que a
            secretaria pode, por exemplo, controlar surtos de sintomas e algumas patologias no dia
            zero.
          </Text>
          <Text>
            Dessa forma oferece oportunidades para que sejam realizadas atividades de prevenção de
            doenças e promoção da saúde em domicílios ou junto a coletividades, em conformidade com
            os princípios e diretrizes do SUS e da Organização Mundial da Saúde &#40;OMS&#41;.
          </Text>
        </Holder>
      </Container>
      <ButtonContainer>
        <Links to={'/artificialIntelligence'} title='Conheça nossos produtos'>
          <OutlinedButton>Conheça nossos produtos</OutlinedButton>
        </Links>
        <LinkAct href={'#Contact'} title='Converse com nosso consultor'>
          <OrangeButton>Converse com nosso consultor</OrangeButton>
        </LinkAct>
      </ButtonContainer>
    </Section>
  )
}

export default AgilityDesktopElements
