import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import DarkOrangeCube from './../../images/cubesAndMarkers/darker_orange_cube.png'
import BrightMobileCube from './../../images/cubesAndMarkers/bright_mobile_cube.png'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 30vh;
  background-color: ${primaryColors.blue};

  @media (max-width: 768px) {
    /* display: none; */
    height: 4vh;
  }
`

const DarkerOrangeCubeImage = styled.img`
  position: relative;
  flex: 1;
  display: flex;
  align-self: flex-end;
  object-fit: contain;
  width: 74px;
  height: 83px;
  top: -280px;

  @media (max-width: 768px) {
    display: none;
  }
`

const BrightMobileCubeImage = styled.input`
  position: relative;
  width: 60px;
  height: 72px;
  top: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`

function BlankSpace() {
  return (
    <Section>
      <BrightMobileCubeImage
        type='image'
        src={BrightMobileCube}
        alt='Cubo laranja pequeno atrás do vídeo'
      />
      <DarkerOrangeCubeImage src={DarkOrangeCube} alt='Cubo laranja preenchido' />
    </Section>
  )
}

export default BlankSpace
