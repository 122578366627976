import React from 'react'
import { useMedia } from 'react-use'
import AgilityDesktopElements from './agilityComponents/desktopElements'
import AgilityMobileElements from './agilityComponents/mobileElements'

function Agility() {
  const isMobile = useMedia('(max-width: 768px)')

  return isMobile ? <AgilityMobileElements /> : <AgilityDesktopElements />
}

export default Agility
