import React, { useState } from 'react'
import { useMedia } from 'react-use'
import { primaryColors, selectedColors } from 'src/design/design'
import styled from 'styled-components'
import { ReactComponent as DownArrow } from './../../images/down-more-arrow.svg'
import GreenCube from './../../images/cubesAndMarkers/green_cube.png'
import BigOrangeCube from './../../images/cubesAndMarkers/big-orange-cube.png'
import HalfBigOrangeCube from './../../images/cubesAndMarkers/half-big-complete-orange.png'
import RightHalfCutCube from './../../images/cubesAndMarkers/right-half-cut-cube.png'
import NeuronHalfOrangeCube from './../../images/cubesAndMarkers/neuron-half-orange-cube.png'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${selectedColors.blue};
  height: auto;
`

const Title = styled.h1`
  color: ${primaryColors.blue};
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  text-align: center;
  padding-left: 500px;
  padding-right: 500px;
  margin-bottom: 220px;
  margin-top: 100px;

  @media (max-width: 1500px) {
    padding-left: 250px;
    padding-right: 250px;
  }

  @media (max-width: 950px) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 40px;
    margin-top: 95px;
  }
`

const Elements = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  gap: 13px 150px;
  text-align: left;
  margin-bottom: 10px;
  margin-right: 235px;
  margin-left: 235px;

  @media (max-width: 1100px) {
    gap: 13px 35px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 100px;
  }
`

const Element = styled.div``

const ElementText = styled.p`
  color: ${primaryColors.blue};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
  }
`

const ShowMore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`

const ShowMoreText = styled.h1`
  font-weight: 500;
  font-size: 500;
  font-size: 16px;
  line-height: 21px;
  padding-top: 5px;
`

const GreenCubeImage = styled.img`
  width: 60px;
  height: 67px;
  margin-top: -40px;
  margin-left: 280px;

  @media (max-width: 768px) {
    margin-left: 0;
    display: flex;
    align-self: flex-end;
  }
`

const OrangeCubeImage = styled.img`
  display: flex;
  align-self: flex-end;
  width: 194px;
  height: 197px;
  margin-top: -90px;

  @media (max-width: 768px) {
    display: none;
  }
`

const MobileOrangeCubeImage = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 96px;
    height: 97px;
    display: flex;
    align-self: flex-start;
    margin-bottom: 40px;
  }
`

const RightHalfCutCubeImage = styled.img`
  width: 108px;
  height: 170px;
  display: flex;
  align-self: flex-end;
  position: relative;
  /* top: px; */

  @media (max-width: 768px) {
    display: none;
  }
`

const NeuronHalfOrangeCubeImage = styled.img`
  width: 150px;
  height: 189px;
  display: flex;
  align-self: flex-start;
  position: relative;
  top: -140px;

  @media (max-width: 988px) {
    top: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

function Advantage() {
  const isMobile = useMedia('(max-width: 768px)')
  const [showAll, setShowAll] = useState<boolean>(false)

  const ShowAllAdvantages = () => {
    setShowAll(true)
  }

  return (
    <Section>
      <GreenCubeImage src={GreenCube} alt='Cubo verde' />
      <OrangeCubeImage src={BigOrangeCube} alt='Cubo laranja preenchido' />
      <Title>Conheça as vantagens do Prix Saúde</Title>
      <Elements>
        <Element>
          <ElementText>• Software de consultoria em tempo real.</ElementText>
        </Element>
        <Element>
          <ElementText>
            • Informações claras e de simples acesso para organizar a massividade de dados
            sensíveis.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Simplifique a busca por informações e ganhe mais tempo para focar no que realmente
            importa.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Com o prixNeuron fica muito mais fácil fazer a gestão financeira de saúde, pois por
            mapas e gráficos os gestores sabem onde o dinheiro está sendo aplicado.
          </ElementText>
        </Element>
        <Element>
          <ElementText>
            • Tenha as informações do cidadão sempre na palma da mão e proporcione uma experiência
            mais individualizada.
          </ElementText>
        </Element>
        {isMobile && !showAll ? (
          <ShowMore onClick={() => ShowAllAdvantages()}>
            <DownArrow />
            <ShowMoreText>Conheça todas as vantagens</ShowMoreText>
          </ShowMore>
        ) : null}
        {isMobile && showAll ? (
          <>
            <Element>
              <ElementText>• A plataforma é segura, clara e fácil de utilizar.</ElementText>
            </Element>
            <Element>
              <ElementText>
                • Centralize e condense as informações de demandas da saúde.
              </ElementText>
            </Element>
            <Element>
              <ElementText>
                • Compilação das informações da saúde em uma única plataforma, sem retrabalho.
              </ElementText>
            </Element>
            <Element>
              <ElementText>
                • Tenha todos os procedimentos realizados e acompanhe a evolução do cidadão na linha
                do tempo
              </ElementText>
            </Element>
            <Element>
              <ElementText>• Desempenho de trabalho das ACs.</ElementText>
            </Element>
            <Element>
              <ElementText>
                • Organização, informação e segurança em cada dado. Isso reflete a qualidade do seu
                trabalho.
              </ElementText>
            </Element>
            <Element>
              <ElementText>• Controle de dispensa de medicamento.</ElementText>
              <ElementText style={{ marginTop: '30px' }}>• Controle de viagens.</ElementText>
            </Element>
          </>
        ) : null}
        {!isMobile ? (
          <>
            <Element>
              <ElementText>• A plataforma é segura, clara e fácil de utilizar.</ElementText>
            </Element>
            <Element>
              <ElementText>
                • Centralize e condense as informações de demandas da saúde.
              </ElementText>
            </Element>
            <Element>
              <ElementText>
                • Compilação das informações da saúde em uma única plataforma, sem retrabalho.
              </ElementText>
            </Element>
            <Element>
              <ElementText>
                • Tenha todos os procedimentos realizados e acompanhe a evolução do cidadão na linha
                do tempo
              </ElementText>
            </Element>
            <Element>
              <ElementText>• Desempenho de trabalho das ACs.</ElementText>
            </Element>
            <Element>
              <ElementText>
                • Organização, informação e segurança em cada dado. Isso reflete a qualidade do seu
                trabalho.
              </ElementText>
            </Element>
            <Element>
              <ElementText>• Controle de dispensa de medicamento.</ElementText>
              <ElementText style={{ marginTop: '30px' }}>• Controle de viagens.</ElementText>
            </Element>
          </>
        ) : null}
      </Elements>
      <MobileOrangeCubeImage src={HalfBigOrangeCube} alt='Cubo não preenchido laranja' />
      <NeuronHalfOrangeCubeImage
        src={NeuronHalfOrangeCube}
        alt='Cubo não preenchido laranja cortado pela metade posicionado ao lado esquerdo'
      />
      <RightHalfCutCubeImage
        src={RightHalfCutCube}
        alt='Cubo não preenchido laranja cortado pela metade posicionado ao lado direito'
      />
    </Section>
  )
}

export default Advantage
