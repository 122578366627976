/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useKey from 'react-use/lib/useKey'
import styled from 'styled-components'
import { ReactComponent as Close } from './../../../images/close.svg'

interface ModalProps {
  children: React.ReactNode
  className?: string
  dialogClassName?: string
  isVisible?: boolean
  onClose?: () => void
}

let currentZIndex = 10

const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 30px;
  margin-right: 30px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  .CloseButton {
    filter: brightness(0) invert(1);
  }
`

export default function ModalHamburger({
  isVisible = true,
  className = '',
  dialogClassName = 'relative flex',
  onClose,
  children,
}: ModalProps) {
  const wrapperRef = useRef<HTMLDivElement>()
  const dialogRef = useRef<HTMLDivElement>(null)
  const [zIndex, setZIndex] = useState(0)

  useEffect(() => {
    const modalBodyElement = document.getElementById('root')
    const newModal = document.createElement('div')
    if (modalBodyElement) modalBodyElement.appendChild(newModal)
    wrapperRef.current = newModal

    currentZIndex += 1
    setZIndex(currentZIndex)

    return () => {
      if (modalBodyElement && newModal) {
        modalBodyElement.removeChild(newModal)
      }
    }
  }, [])

  useKey('Escape', event => {
    event.stopPropagation()
    event.preventDefault()

    if (onClose) {
      onClose()
    }
  })

  useKey('Escape', event => {
    event.stopPropagation()
    event.preventDefault()

    if (onClose) {
      onClose()
    }
  })

  const handleClickOverlay = (event: React.MouseEvent) => {
    event.preventDefault()

    if (onClose) {
      onClose()
    }
  }
  const handleClickClose = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (onClose) {
      onClose()
    }
  }
  const handleClickDialog = (event: React.MouseEvent) => event.stopPropagation()

  if (wrapperRef.current && isVisible) {
    return createPortal(
      <div className={className} style={{ zIndex }} onClick={handleClickOverlay}>
        <div role='dialog' className={dialogClassName} ref={dialogRef} onClick={handleClickDialog}>
          {children}
          <Button type='button' onClick={handleClickClose}>
            <Close className='CloseButton' width={25} height={25} fill={'#fff'} />
          </Button>
        </div>
      </div>,
      wrapperRef.current,
    )
  }
  return null
}
