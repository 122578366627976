import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import Carousel from './carousel'

const Container = styled.div`
  display: flex;
  flex: 1 0 50%;
  gap: 10px;
  flex-direction: row;
  background: #fff;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 100px;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const TopDescription = styled.div`
  display: flex;
  padding: 20px 10%;
`

const BottomDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10%;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

const Title = styled.h2`
  color: ${primaryColors.blue};
  font-size: 1.8em;
  font-weight: 700;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${primaryColors.gray};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;
`

const ButtonHolder = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 974px) {
    align-self: center;
  }
`

export const OrangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 295px;
  height: 45px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: all 0.15s;
  appearance: button;
  background-color: ${primaryColors.orange};
  border: 1px solid ${primaryColors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;

  &:hover {
    transition: 0.9s;
    transform: scale(1.07) perspective(0.9px);
  }

  @media (max-width: 1140px) {
    height: 35px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 315px;
    height: 35px;
    font-size: 15px;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

function MobileElementCompanyAbout() {
  return (
    <Container>
      <DescriptionContainer>
        <TopDescription>
          <Title>
            Juntos produzimos com excelência para deixar um legado, melhorar a qualidade de vida das
            pessoas e contribuir de forma ativa com o desenvolvimento do país
          </Title>
        </TopDescription>
        <BottomDescription>
          <Text>
            Fundada em 2017, a Prix Tech começou como uma pequena equipe em Luzerna, Santa Catarina
            e evolui constantemente para despontar como referência no desenvolvimento de soluções de
            inteligência e gestão estratégica.
          </Text>
          <Text>
            Nossa experiência nos permite desenvolver aplicações voltadas à melhor experiência do
            usuário. Acreditamos que as constantes transformações do mercado nos permitem evoluir e
            por isso nos dedicamos de forma integral na busca de novas soluções e alternativas.
          </Text>
          <Carousel />
          <ButtonHolder>
            <LinkAct href='#Contact' title='Converse com nosso consultor'>
              <OrangeButton>Converse com nosso consultor</OrangeButton>
            </LinkAct>
          </ButtonHolder>
        </BottomDescription>
      </DescriptionContainer>
    </Container>
  )
}

export default MobileElementCompanyAbout
