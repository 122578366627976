import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Text = styled.h1`
  color: #fff;
  font-size: 2.5em;
  font-weight: 700;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

const wordArray = [
  'análise de dados',
  'soluções disruptivas',
  'inteligência artificial',
  'análise temporal',
  'gestão estratégica',
  'georreferenciamento',
]

function WordChanging() {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const id = setTimeout(() => setCurrentIndex((currentIndex + 1) % wordArray.length), 5000)
    return () => {
      clearInterval(id)
    }
  }, [currentIndex])

  return (
    <Container>
      <Text>Somos especialistas em: </Text>
      <Text>{wordArray[currentIndex]}</Text>
    </Container>
  )
}

export default WordChanging
