import React, { useCallback, useState } from 'react'
import Tania from './../../../images/bosses/tania.jpg'
import Petry from './../../../images/bosses/petry.jpg'
import Calza from './../../../images/bosses/calza.jpg'
import styled from 'styled-components'
import { selectedColors } from 'src/design/design'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 651px;
  width: 431px;
  z-index: 0;

  article {
    position: absolute;
    height: 100%;
    width: 33.33%;
    background-color: ${selectedColors.gray};
    z-index: 1;
    transition: width 0.4s linear, left 0.4s linear;

    img {
      height: 651px;
      width: 100%;
      object-fit: cover;
      filter: grayscale(1);
      transition: filter 0.4s linear;
    }

    main {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 90px;
      text-align: center;
      background-color: rgba(12, 35, 64, 0.7);
      transition: opacity 0.4s linear;
      opacity: 0;
    }

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      left: 33.33%;
    }

    &:nth-child(3) {
      left: 66.66%;
    }

    &.active {
      z-index: 2;
      left: 0;
      width: 100%;

      img {
        filter: grayscale(0);
      }

      main {
        opacity: 1;
      }
    }
  }
`

const Name = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #fff;
`

const Title = styled.h1`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #fff;
`

function Bosses() {
  const [activeImage, setActiveImage] = useState<number | null>(null)
  const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const bounding = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - bounding.left
    const width = bounding.width

    if (x < 0 || x > width) {
      setActiveImage(null)
      return
    }

    const imagesLength = 3
    const imageIndex = Math.floor((x / width) * imagesLength)
    setActiveImage(imageIndex)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setActiveImage(null)
  }, [])

  console.log(activeImage)

  return (
    <Wrapper
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <article className={activeImage == 0 ? 'active' : ''}>
        <img src={Tania} alt='Foto da CMO da Prix.Tech Tânia Patzlaff' />
        <main>
          <Name>Tânia Patzlaff</Name>
          <Title>CMO</Title>
        </main>
      </article>
      <article className={activeImage == 1 ? 'active' : ''}>
        <img src={Calza} alt='Foto do CTO da Prix.Tech Mateus Calza' />
        <main>
          <Name>Mateus Calza</Name>
          <Title>CTO</Title>
        </main>
      </article>
      <article className={activeImage == 2 ? 'active' : ''}>
        <img src={Petry} alt='Foto do CEO da Prix.Tech Felippe Petry' />
        <main>
          <Name>Felippe Petry</Name>
          <Title>CEO</Title>
        </main>
      </article>
    </Wrapper>
  )
}

export default Bosses
