import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import { LargeOutlinedButton } from '../reusable/buttons'
import { ReactComponent as DataAnalysis } from './../../images/whiteLabelIcons/orange/data-analysis.svg'
import { ReactComponent as StrategicManagement } from './../../images/whiteLabelIcons/orange/strategic-management.svg'
import { ReactComponent as Georeferencing } from './../../images/whiteLabelIcons/orange/georeferencing.svg'
import { ReactComponent as DisruptiveSolutions } from './../../images/whiteLabelIcons/orange/disruptive-solutions.svg'
import { ReactComponent as ArtificialIntelligence } from './../../images/whiteLabelIcons/orange/artificial-intelligence.svg'
import { ReactComponent as TemporalAnalysis } from './../../images/whiteLabelIcons/orange/temporal-analysis.svg'
import OrangeMarker from './../../images/cubesAndMarkers/outlined_orange_marker.png'
import ReverseOrangeCube from './../../images/cubesAndMarkers/reversed_orange_cube.png'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  background-color: ${primaryColors.blue};
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 48px;
  color: ${primaryColors.orange};
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 36px;
    text-align: left;
    margin-bottom: 60px;
    padding: 5px 1%;
  }
`

const HideMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const HideWeb = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  @media (min-width: 768px) {
    display: none;
  }
`

const Holder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 210px;
  margin-bottom: 230px;
  margin-left: 200px;
  margin-right: 200px;
  gap: 0px 200px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0;
    margin-bottom: 0px;
    gap: 0px;
  }
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Info = styled.div`
  @media (max-width: 768px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 30px;
  }
`

const Elements = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  gap: 55px 120px;
  text-align: center;

  @media (max-width: 768px) {
    gap: 30px;
  }
`

const Element = styled.div`
  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid ${primaryColors.orange};
    border-radius: 5px;
  }
`

const ElementText = styled.p`
  font-weight: 700;
  color: #fff;
  font-size: 14px;
`

const MarkerImage = styled.img`
  display: flex;
  align-self: center;
  position: relative;
  width: 90px;
  height: 100px;
  top: -40px;

  @media (max-width: 768px) {
    align-self: flex-end;
  }
`

const SmallerCubeImage = styled.img`
  display: flex;
  align-self: flex-start;
  position: relative;
  left: 0px;
  top: 40px;

  @media (min-width: 768px) {
    display: none;
  }
`

const LinkAct = styled.a`
  text-decoration: none;
`

function WhiteLabelScreen() {
  return (
    <>
      <Section>
        <MarkerImage src={OrangeMarker} alt='Marcador de coordenadas laranja' />
        <Holder>
          <Container>
            <Info>
              <Title>Soluções empresariais white label</Title>
              <Text>
                Soluções para realizar análises de dados e apresentá-los de forma visual e
                assertiva, para que assim, os gestores sejam capazes de ter uma visão ampla de
                negócio, bem como, possam visualizar todas as oportunidades existentes.
              </Text>
              <HideMobile>
                <LinkAct href={'/artificialIntelligence'} title={'Saiba mais sobre White Label'}>
                  <LargeOutlinedButton>Saiba mais sobre White Label</LargeOutlinedButton>
                </LinkAct>
              </HideMobile>
            </Info>
          </Container>
          <SmallerCubeImage src={ReverseOrangeCube} alt='Cubo laranja' />
          <Elements>
            <Element>
              <DataAnalysis width={64} height={64} />
              <ElementText>Análise de Dados</ElementText>
            </Element>
            <Element>
              <StrategicManagement width={64} height={64} />
              <ElementText>Gestão Estratégica</ElementText>
            </Element>
            <Element>
              <Georeferencing width={64} height={64} />
              <ElementText>Georreferenciamento</ElementText>
            </Element>
            <Element>
              <DisruptiveSolutions width={64} height={64} />
              <ElementText>Soluções Disruptivas</ElementText>
            </Element>
            <Element>
              <ArtificialIntelligence width={64} height={64} />
              <ElementText>Inteligência Artificial</ElementText>
            </Element>
            <Element>
              <TemporalAnalysis width={64} height={64} />
              <ElementText>Análise Temporal</ElementText>
            </Element>
          </Elements>
          <HideWeb>
            <LinkAct href={'/artificialIntelligence'} title={'Saiba mais sobre White Label'}>
              <LargeOutlinedButton>Saiba mais sobre White Label</LargeOutlinedButton>
            </LinkAct>
          </HideWeb>
        </Holder>
      </Section>
    </>
  )
}

export default WhiteLabelScreen
