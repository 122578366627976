import React from 'react'
import { primaryColors } from 'src/design/design'
import styled from 'styled-components'
import BackgroundPrototype from './../../images/background-prototype.png'
import BackgroundDarkBlue from './../../images/background-prototype-dark-blue.png'
import BackgroundBabyBlue from './../../images/background-baby-blue.png'
import ContactElements from './../../images/contact-elements.png'
import MobileContactElements from './../../images/contact-elements-mobile.png'
import { ReactComponent as TalkContact } from './../../images/talk-contact.svg'
import { ReactComponent as EmailContact } from './../../images/email-contact.svg'
import { ReactComponent as MarkerContact } from './../../images/marker-contact.svg'
import { ReactComponent as Facebook } from './../../images/facebook.svg'
import { ReactComponent as Instagram } from './../../images/instagram.svg'
import { ReactComponent as Linkedin } from './../../images/linkedin.svg'
import FooterBar from './footerBar'

const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-image: url(${BackgroundPrototype});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-color: ${primaryColors.blue};

  @media (max-width: 768px) {
    background-size: none;
  }
`

const DarkBlueSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-image: url(${BackgroundDarkBlue});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-color: ${primaryColors.blue};

  @media (max-width: 768px) {
    background-size: none;
  }
`

const BabyBlueSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-image: url(${BackgroundBabyBlue});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-color: ${primaryColors.blue};

  @media (max-width: 768px) {
    background-size: none;
  }
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 140px;
  padding-top: 500px;

  @media (max-width: 800px) {
    gap: 0;
  }

  @media (max-width: 768px) {
    padding-top: 120px;
    flex-direction: column;
    gap: 0;
    padding-top: 40px;
  }
`

const Info = styled.div``

const Image = styled.img`
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    display: none;
  }
`

const MobileImage = styled.img`
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  height: auto;

  @media (min-width: 768px) {
    display: none;
  }
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 48px;
  color: ${primaryColors.orange};
  padding-bottom: 90px;

  @media (max-width: 768px) {
    padding-bottom: 60px;
    font-size: 32px;
    text-align: center;
  }
`

const SecondInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 200px;

  @media (max-width: 1000px) {
    gap: 50px;
  }

  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: ${primaryColors.orange};
  padding-bottom: 16px;

  @media (max-width: 768px) {
    font-weight: 600;
  }
`

const ContactHolder = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  gap: 13px;
`

const PhoneNumberContactHolder = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 32px;
  gap: 13px;
`

const PhoneNumberHolder = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.span`
  color: #ffffff;
  text-decoration: none;

  @media (max-width: 768px) {
    font-weight: 400;
  }
`

const Links = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`

const SocialMediaLink = styled.a``

const SocialMediaContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

interface FooterProps {
  darkBlue?: boolean | undefined
  babyBlue?: boolean | undefined
}

function Footer({ darkBlue, babyBlue }: FooterProps) {
  return darkBlue ? (
    <DarkBlueSection id='Contact'>
      <Container>
        <Image src={ContactElements} alt='Balão de diálogo representando a área de contato' />
        <MobileImage
          src={MobileContactElements}
          alt='Balão de diálogo representando a área de contato'
        />
        <Info>
          <Title>Ficou com alguma dúvida sobre as soluções da Prix.Tech?</Title>
          <SecondInfo>
            <ContactInfo>
              <ContactTitle>FALE CONOSCO</ContactTitle>
              <ContactHolder>
                <EmailContact width={24} height={24} />
                <Links href='mailto: contato@prix.tech'>contato@prix.tech</Links>
              </ContactHolder>
              <PhoneNumberContactHolder>
                <TalkContact width={24} height={24} />
                <PhoneNumberHolder>
                  <Text>49 99921.6226 &#40;CEO&#41;</Text>
                  <Text>49 99919.0267 &#40;CTO&#41;</Text>
                </PhoneNumberHolder>
              </PhoneNumberContactHolder>
            </ContactInfo>
            <ContactInfo>
              <ContactTitle>ONDE ESTAMOS</ContactTitle>
              <ContactHolder>
                <MarkerContact width={24} height={24} />
                <Text>Luzerna • SC • Brasil</Text>
              </ContactHolder>
              <ContactHolder>
                <SocialMediaContainer>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.facebook.com/PrixTechSoftware'
                    title='Ir para o Facebook da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Facebook width={32} height={32} />
                  </SocialMediaLink>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.instagram.com/prixtechsoftware/'
                    title='Ir para o Instagram da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Instagram width={32} height={32} />
                  </SocialMediaLink>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.linkedin.com/company/prix-tech/'
                    title='Ir para o LinkedIn da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Linkedin width={32} height={32} />
                  </SocialMediaLink>
                </SocialMediaContainer>
              </ContactHolder>
            </ContactInfo>
          </SecondInfo>
        </Info>
      </Container>
      <FooterBar />
    </DarkBlueSection>
  ) : babyBlue ? (
    <BabyBlueSection id='Contact'>
      <Container>
        <Image src={ContactElements} alt='Balão de diálogo representando a área de contato' />
        <MobileImage
          src={MobileContactElements}
          alt='Balão de diálogo representando a área de contato'
        />
        <Info>
          <Title>Ficou com alguma dúvida sobre as soluções da Prix.Tech?</Title>
          <SecondInfo>
            <ContactInfo>
              <ContactTitle>FALE CONOSCO</ContactTitle>
              <ContactHolder>
                <EmailContact width={24} height={24} />
                <Links href='mailto: contato@prix.tech'>contato@prix.tech</Links>
              </ContactHolder>
              <PhoneNumberContactHolder>
                <TalkContact width={24} height={24} />
                <PhoneNumberHolder>
                  <Text>49 99921.6226 &#40;CEO&#41;</Text>
                  <Text>49 99919.0267 &#40;CTO&#41;</Text>
                </PhoneNumberHolder>
              </PhoneNumberContactHolder>
            </ContactInfo>
            <ContactInfo>
              <ContactTitle>ONDE ESTAMOS</ContactTitle>
              <ContactHolder>
                <MarkerContact width={24} height={24} />
                <Text>Luzerna • SC • Brasil</Text>
              </ContactHolder>
              <ContactHolder>
                <SocialMediaContainer>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.facebook.com/PrixTechSoftware'
                    title='Ir para o Facebook da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Facebook width={32} height={32} />
                  </SocialMediaLink>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.instagram.com/prixtechsoftware/'
                    title='Ir para o Instagram da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Instagram width={32} height={32} />
                  </SocialMediaLink>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.linkedin.com/company/prix-tech/'
                    title='Ir para o LinkedIn da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Linkedin width={32} height={32} />
                  </SocialMediaLink>
                </SocialMediaContainer>
              </ContactHolder>
            </ContactInfo>
          </SecondInfo>
        </Info>
      </Container>
      <FooterBar />
    </BabyBlueSection>
  ) : (
    <Section id='Contact'>
      <Container>
        <Image src={ContactElements} alt='Balão de diálogo representando a área de contato' />
        <MobileImage
          src={MobileContactElements}
          alt='Balão de diálogo representando a área de contato'
        />
        <Info>
          <Title>Ficou com alguma dúvida sobre as soluções da Prix.Tech?</Title>
          <SecondInfo>
            <ContactInfo>
              <ContactTitle>FALE CONOSCO</ContactTitle>
              <ContactHolder>
                <EmailContact width={24} height={24} />
                <Links href='mailto: contato@prix.tech'>contato@prix.tech</Links>
              </ContactHolder>
              <PhoneNumberContactHolder>
                <TalkContact width={24} height={24} />
                <PhoneNumberHolder>
                  <Text>49 99921.6226 &#40;CEO&#41;</Text>
                  <Text>49 99919.0267 &#40;CTO&#41;</Text>
                </PhoneNumberHolder>
              </PhoneNumberContactHolder>
            </ContactInfo>
            <ContactInfo>
              <ContactTitle>ONDE ESTAMOS</ContactTitle>
              <ContactHolder>
                <MarkerContact width={24} height={24} />
                <Text>Luzerna • SC • Brasil</Text>
              </ContactHolder>
              <ContactHolder>
                <SocialMediaContainer>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.facebook.com/PrixTechSoftware'
                    title='Ir para o Facebook da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Facebook width={32} height={32} />
                  </SocialMediaLink>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.instagram.com/prixtechsoftware/'
                    title='Ir para o Instagram da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Instagram width={32} height={32} />
                  </SocialMediaLink>
                  <SocialMediaLink
                    target='_blank'
                    href='https://www.linkedin.com/company/prix-tech/'
                    title='Ir para o LinkedIn da Prix.Tech (link externo)'
                    rel='noopener noreferrer'
                  >
                    <Linkedin width={32} height={32} />
                  </SocialMediaLink>
                </SocialMediaContainer>
              </ContactHolder>
            </ContactInfo>
          </SecondInfo>
        </Info>
      </Container>
      <FooterBar />
    </Section>
  )
}

export default Footer
