import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ModalHamburger from './modalHamburger'
import { ReactComponent as Facebook } from './../../../images/facebook.svg'
import { ReactComponent as Instagram } from './../../../images/instagram.svg'
import { ReactComponent as Linkedin } from './../../../images/linkedin.svg'
import { complementaryColors, primaryColors } from 'src/design/design'

interface HomeNavMobileMenuProps {
  isVisible?: boolean
  isLoginPage?: boolean
  onClose?: () => void
}

const ModalMenu = styled(ModalHamburger)`
  background-color: rgba(12, 35, 64, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const Menu = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
`

const MenuItem = styled.li`
  padding: 18px 50px 20px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  a {
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
  }
  &:hover a {
    color: #fff;
    border-bottom: 0px;
  }
  &:before {
    content: '';
    width: 0%;
    height: 3px;
    background: ${primaryColors.orange};
    display: table;
    position: absolute;
    top: 0px;
    left: 50%;
    right: 50%;

    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
  }
  &:after {
    content: '';
    width: 0%;
    height: 3px;
    background: ${primaryColors.orange};
    display: table;
    position: absolute;
    bottom: 0px;
    left: 50%;
    right: 50%;

    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
  }
  &:hover:before {
    width: 100%;
    left: 0%;
    right: 0%;
  }
  &:hover:after {
    width: 100%;
    left: 0%;
    right: 0%;
  }
`

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 4rem;
  font-weight: 700;
  display: inline;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: ${complementaryColors.graySwan};
    border-bottom: 1px solid;
    border-bottom-color: ${complementaryColors.crowBlackBlue};
    cursor: pointer;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 3rem;
  }
`

const SocialMediaLink = styled.a``

const SocialMediaContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 30px;
  margin-top: 20px;
  justify-content: center;
`

export default function HomeNavMobileMenu({ isVisible, onClose }: HomeNavMobileMenuProps) {
  return (
    <ModalMenu isVisible={isVisible} onClose={onClose}>
      <Menu>
        <MenuItem onClick={onClose}>
          <MenuLink to='/'>Início</MenuLink>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <MenuLink to='/about'>Quem somos</MenuLink>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <MenuLink to='/artificialIntelligence'>Soluções em negócios</MenuLink>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <MenuLink to='/neuron'>Solução em saúde</MenuLink>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <MenuLink to='/prizes'>Premiações</MenuLink>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <MenuLink to='/careers'>Trabalhe conosco</MenuLink>
        </MenuItem>
        <SocialMediaContainer>
          <SocialMediaLink
            target='_blank'
            href='https://www.facebook.com/PrixTechSoftware'
            title='Ir para o Facebook da Prix.Tech (link externo)'
            rel='noopener noreferrer'
          >
            <Facebook width={42} height={42} />
          </SocialMediaLink>
          <SocialMediaLink
            target='_blank'
            href='https://www.instagram.com/prixtechsoftware/'
            title='Ir para o Instagram da Prix.Tech (link externo)'
            rel='noopener noreferrer'
          >
            <Instagram width={42} height={42} />
          </SocialMediaLink>
          <SocialMediaLink
            target='_blank'
            href='https://www.linkedin.com/company/prix-tech/'
            title='Ir para o LinkedIn da Prix.Tech (link externo)'
            rel='noopener noreferrer'
          >
            <Linkedin width={42} height={42} />
          </SocialMediaLink>
        </SocialMediaContainer>
      </Menu>
    </ModalMenu>
  )
}
