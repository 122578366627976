import ReactGA from 'react-ga4'

//Measurement ID do Google Analytics 4.
const GA_MEASUREMENT_ID = 'G-QZ9M989MZD'

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID)
}

export const logPageView = (url: string) => {
  ReactGA.send({ hitType: 'pageview', page: url })
}
